import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  FuiButton,
  FuiButtonNoOutline,
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import { authService } from "../../services/AuthService";
import Loading from "../shared/Loading";
import Content from "../shared/Content";
import FormInput from "../shared/FormInput";
import Steps from "../shared/Steps";
import Error from "../shared/Error";
import ForgotPassword from "../forgotpassword/ForgotPassword";
import AcceptUserAgreements from "./AcceptUserAgreements";

export default class Login extends Component {
  constructor(props) {
    super(props);

    const {
      props: { location },
    } = this;

    const params = new URLSearchParams(location.search);
    const returnUrl = params.get("ReturnUrl");
    let isErrored = false;
    let error = null;
    let deviceId = localStorage.getItem("deviceId");
    let errorDescription = null;

    if (!returnUrl || returnUrl.length === 0) {
      isErrored = true;
      error = "returnUrl_required";
      errorDescription = "Return url is a required parameter";
    }

    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceId", deviceId);
    }

    this.state = {
      loading: false,
      returnUrl,
      currentStep: 1,
      email: "",
      redirect: "",
      deviceId,
      browser: navigator.userAgent,
      isErrored,
      error,
      errorDescription,
      password: "",
      isResendOtpDisabled: true
    };
  }

  static getDerivedStateFromProps(props) {
    return { ...props };
  }

  submitLogin = (loginRequest) => {
    authService.authenticate(loginRequest);
  };

  submitPreLogin = (loginRequest) => {
    authService.preAuthenticate(loginRequest);
  };

  componentDidMount() {
    this.subscription = authService.subscribe(this.onStateChanged);
    const { returnUrl } = this.state;
    authService.startLogin(returnUrl);
  }

  componentWillUnmount() {
    authService.unsubscribe(this.subscription);
  }

  shouldComponentUpdate = () => {
    return true;
  };

  onStateChanged = () => {
    const { loginState } = authService.getState();

    if(loginState.currentStep === 3 && loginState.isResendOtpDisabled) {
      this.enableResendOtpButton(5000);
    }

    this.setState({ ...loginState });
  };

  onChange = (e) => {
    switch (e.target.name) {
      case "email":
        this.setState({
          email: e.target.value,
        });
        break;

      case "change-password":
        this.setState({
          newPasswordRequested: e.target.checked,
        });
        break;

      case "otp":
        this.setState({
          otpNumber: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  onPasswordChange = (value) => {
    this.setState({
      password: value,
    });
  };

  onPasswordVisibilityChange = (isVisible) => {
    this.setState({
      revealPassword: isVisible,
    });
  };

  onClick = () => {
    const { currentStep, email, password, newPasswordRequested } = this.state;

    if (currentStep === 1 && email) {
      this.submitPreLogin({
        ...this.state,
        email,
        currentStep: 2,
        loading: true,
      });
    } else if (currentStep === 2 && password && password !== null) {
      this.submitLogin({
        ...this.state,
        password,
        newPasswordRequested,
        currentStep: 3,
        loading: true,
      });
    } else if (currentStep === 3) {
      this.submitLogin({
        ...this.state,
        loading: true,
      });
    }
  };

  onAgreementsAccepted = () => {
    const { mustAcceptUserAgreements } = this.state;

    const acceptedUserAgreements = mustAcceptUserAgreements.map((m) => {
      return m.id;
    });

    this.submitLogin({
      ...this.state,
      loading: true,
      acceptedUserAgreements,
    });
  };

  forgotPassword = () => {
    authService.resetState();
    this.setState({
      forgotPassword: true,
    });
  };

  resendOnClick = () => {
    this.setState({ isResendOtpDisabled: true });
    authService.resendOtp({...this.state, isResendOtpDisabled: true});
    this.enableResendOtpButton(10000);
  };

  enableResendOtpButton = (delay) => {
    setTimeout(() => {
      this.setState({ isResendOtpDisabled: false });
    }, delay);
  };

  render() {
    const {
      state: {
        loading,
        currentStep,
        email,
        errorDescription,
        isErrored,
        mustUpdatePassword,
        mustAcceptUserAgreements,
        redirect,
        error,
        requestId,
        forgotPassword,
        returnUrl,
        clientUri,
        userId,
        clientId,
        token,
        redirectIfNoReferrer,
      },
      props: { renderRedirect, location },
    } = this;

    if (!document.referrer && clientUri && redirectIfNoReferrer) {
      window.location.replace(clientUri);
    }

    if (loading) {
      return <Loading />;
    }

    if (forgotPassword) {
      return (
        <ForgotPassword
          email={email}
          renderRedirect={renderRedirect}
          applicationReturnUrl={returnUrl}
        />
      );
    }

    if (redirect && redirect !== "") {
      return renderRedirect(redirect, returnUrl);
    }

    if (isErrored) {
      return (
        <Error
          error={error}
          errorDescription={errorDescription}
          requestId={requestId}
          location={location}
        />
      );
    }

    if (mustUpdatePassword) {
      return renderRedirect("password", returnUrl, userId, clientId, token);
    }

    if (mustAcceptUserAgreements) {
      return (
        <AcceptUserAgreements
          userAgreements={mustAcceptUserAgreements}
          onAgreementsAccepted={this.onAgreementsAccepted}
        ></AcceptUserAgreements>
      );
    }

    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="large">
          <FuiFlexItem>
            <FuiHeading>Sign In</FuiHeading>
          </FuiFlexItem>
          {this.renderLoginMessage(errorDescription)}
          {this.renderEmailField(
            currentStep,
            this.onChange,
            this.onClick,
            email,
          )}
          {this.renderPasswordField(
            currentStep,
            this.onPasswordChange,
            this.onPasswordVisibilityChange,
            this.onClick,
          )}
          {this.renderOtpField(currentStep, this.onChange, this.onClick)}
          {this.renderStepIndicator(3, currentStep)}
          {this.renderSubmitButton()}
          <FuiFlexItem>
            <FuiButtonNoOutline id="passwordResetLink" onClick={this.forgotPassword}>
              Need help Signing in?
            </FuiButtonNoOutline>
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiButtonNoOutline id="privacyPolicyLink" href='https://www.forcura.com/privacypolicy' target="_blank">
              Privacy Policy
            </FuiButtonNoOutline>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }

  renderLoginMessage = (errorDescription) => {
    if (errorDescription) {
      return (
        <FuiFlexItem padding="none">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <span className="error">{errorDescription}</span>
        </FuiFlexItem>
      );
    }

    return <> </>;
  };

  renderEmailField = (step, onChange, onClick, defaultValue) => {
    if (step === 1 || step === 2) {
      return (
        <FormInput
          key={1}
          id="userName"
          name="email"
          defaultValue={defaultValue}
          placeholder="Email"
          onChange={onChange}
          onClick={onClick}
          focus={step === 1}
        />
      );
    }

    return <></>;
  };

  renderOtpField = (step, onChange, onClick) => {
    if (step === 3) {
      return (
        <>
          <FuiFlexItem padding="none">
            <span className="message">
              We have sent a passcode to your phone.
            </span>
          </FuiFlexItem>
          <FormInput
            key="otp"
            name="otp"
            placeholder="Passcode"
            onChange={onChange}
            onClick={onClick}
            focus={step === 3}
            defaultValue=""
          />
        </>
      );
    }

    return <></>;
  };

  renderPasswordField = (
    step,
    onChange,
    onPasswordVisibilityChange,
    onClick,
  ) => {
    const { revealPassword, password } = this.state;

    if (step === 2) {
      return (
        <FormInput
          password
          key={2}
          id="password"
          name="password"
          placeholder="Password"
          className="inputbox"
          revealPassword={revealPassword}
          onChange={onChange}
          onPasswordVisibilityChange={onPasswordVisibilityChange}
          value={password}
          onClick={onClick}
          focus={step === 2}
          defaultValue=""
        />
      );
    }

    return <></>;
  };

  renderStepIndicator = (totalSteps, currentStep) => {
    return (
      <FuiFlexItem>
        <Steps totalSteps={totalSteps} currentStep={currentStep} />
      </FuiFlexItem>
    );
  };

  renderSubmitButton = () => {
    const { currentStep, isResendOtpDisabled } = this.state;
   
    const text = currentStep !== 3 ? "Next" : "Sign In";
    const id = currentStep !== 3 ? "next" : "signIn";

    if (currentStep === 3) {
      return (
          <FuiFlexItem className="wrapOnExtraSmall" justifyContent="center">
            <FuiButton noFill onClick={this.resendOnClick} isDisabled={isResendOtpDisabled}>
              Resend
            </FuiButton>
            <FuiButton onClick={this.onClick}>{text}</FuiButton>
          </FuiFlexItem>
      );
    }

    return (
      <FuiFlexItem>
        <FuiButton id={id} onClick={this.onClick}>{text}</FuiButton>
      </FuiFlexItem>
    );
  };
}

Login.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  renderRedirect: PropTypes.func.isRequired,
};
