/* eslint-disable import/no-unused-modules */
import { Paths } from "../helpers/Constants";

export default class AuthService {
  constructor() {
    this.callbacks = [];
    this.nextSubscriptionId = 6000;
    this.loginState = {};
    this.errorState = {};
  }

  resetState() {
    this.loginState = {};
    this.errorState = {};
  }

  async validateToken(request) {
    fetch(`${Paths.ApiPasswordUrl}/ValidateToken`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        token: request.token,
        userId: request.userId,
        clientId: request.clientId,
      }),
    })
      .then((resp) => resp.json())
      .then((payload) =>
        this.handleValidateToken({
          ...request,
          ...payload,
        }),
      );
  }

  handleValidateToken(response) {
    response.loading = false;

    this.updateLoginState(response);
  }

  async startLogin(returnUrl) {
    this.updateLoginState({
      loading: true,
    });
    fetch(`${Paths.ApiStartLoginUrl}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ returnUrl }),
    })
      .then((resp) => resp.json())
      .then((payload) =>
        this.updateLoginState({
          ...payload,
          loading: false,
        }),
      );
  }

  async preAuthenticate(loginRequest) {
    this.updateLoginState(loginRequest);
    if (loginRequest.email) {
      fetch(`${Paths.ApiLoginUrl}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(loginRequest),
      })
        .then((resp) => resp.json())
        .then((payload) =>
          this.handlePreAuthenticateResponse({
            ...loginRequest,
            ...payload,
            loading: false,
          }),
        );
    }
  }

  handlePreAuthenticateResponse = (loginResponse) => {
    if (loginResponse.isFederated) {
      window.location.replace(
        `${Paths.ApiExternalLoginUrl}?id=${loginResponse.federationProviderId}&returnUrl=${loginResponse.returnUrl}&email=${loginResponse.email}&browser=${loginResponse.browser}&deviceId=${loginResponse.deviceId}&ipAddress=${loginResponse.ipAddress}`,
      );
    } else {
      this.updateLoginState({ ...loginResponse, loading: false });
    }
  };

  resendOtp = (loginRequest) => {
    if (loginRequest.email) {
      fetch(`${Paths.ApiLoginUrl}/ResendOtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(loginRequest),
      })
        .then((resp) => resp.json())
        .then((payload) =>
          this.handleAuthenticateResponse({
            ...loginRequest,
            ...payload,
          }),
        );
    }
  };

  impersonate = (userId, returnUrl) => {
    fetch(`/impersonation/impersonate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ userId, returnUrl }),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        window.location.replace(`${resp.returnUrl}`);
      })
      .catch(() => this.updateLoginState({ loading: false }));
  };

  authenticate = (loginRequest) => {
    this.updateLoginState(loginRequest);
    if (
      loginRequest.email &&
      (loginRequest.password || loginRequest.otpNumber)
    ) {
      loginRequest.browserDateTime = new Date().toLocaleString(); //this is a string as we do not want DateOnly.
      fetch(`${Paths.ApiLoginUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(loginRequest),
      })
        .then((resp) => resp.json())
        .then((payload) =>
          this.handleAuthenticateResponse({
            ...loginRequest,
            ...payload,
          }),
        );
    }
  };

  handleAuthenticateResponse = (loginResponse) => {
    if (loginResponse.isAuthenticated) {
      if (loginResponse.mustUpdatePassword) {
        this.updateLoginState({
          ...loginResponse,
          isAuthenticated: false,
          loading: false,
        });
      } else if (loginResponse.mustAcceptUserAgreements) {
        this.updateLoginState({
          ...loginResponse,
          mustAcceptUserAgreements: true,
        });
      } else {
        window.location.replace(`${loginResponse.returnUrl}`);
      }
    } else {
      this.updateLoginState({ ...loginResponse, loading: false });
    }
  };

  changePassword = (request) => {
    this.updateLoginState(request);

    fetch(`${Paths.ApiPasswordUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        token: request.token,
        userId: request.userId,
        clientId: request.clientId,
        newPassword: request.newPassword,
        password: request.password,
        phoneNumber: request.phoneNumber,
      }),
    })
      .then((resp) => resp.json())
      .then((payload) =>
        this.handleChangePasswordResponse({
          ...request,
          ...payload,
        }),
      );
  };

  handleChangePasswordResponse = (passwordResponse) => {
    let { currentStep } = passwordResponse.currentStep;
    let passwordRulesMet = true;
    if (passwordResponse.errorDescription && !passwordResponse.isErrored) {
      currentStep = 1;
      passwordRulesMet = false;
    }

    this.updateLoginState({
      ...passwordResponse,
      loading: false,
      currentStep,
      passwordRulesMet,
    });
  };

  getError = (errorId, callback) => {
    fetch(`${Paths.ApiErrorUrl}?errorId=${errorId}`, {
      method: "GET",
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((payload) => callback(payload));
  };

  sendOtpToConfirmPhoneNumber(mobilePhoneNumber, userId, token, clientId) {
    fetch(`/password/SendOtpToConfirmPhoneNumber`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        token,
        mobilePhoneNumber,
        userId,
        clientId
      }),
    })
    .catch((error) => console.warn(error));
  }

  confirmMobileNumber(confirmMobileNumberRequest) {
    return fetch(`/password/ConfirmMobileNumber`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(confirmMobileNumberRequest),
    });
  }

  handleErrorResponse = (errorResponse) => {
    this.updateLoginState({ ...errorResponse, loading: false });
  };

  updateLoginState = (newState) => {
    this.loginState = newState;
    this.notifySubscribers();
  };

  getState() {
    return {
      loginState: { ...this.loginState },
    };
  }

  subscribe(callback) {
    this.callbacks.push({
      callback,
      // eslint-disable-next-line no-plusplus
      subscription: this.nextSubscriptionId++,
    });
    return this.nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this.callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false },
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      );
    }

    this.callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this.callbacks.length; i += 1) {
      const { callback } = this.callbacks[i];
      callback();
    }
  }
}

export const authService = new AuthService();
