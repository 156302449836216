import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import {
  FuiButton,
  FuiIcon,
  FuiFlexItem,
  FuiHeading,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import { termsAndConditionsService } from "../../../services/TermsAndConditionsService";
import Content from "../../shared/Content";
import Loading from "../../shared/Loading";

export default class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: undefined,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getTerms();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getTerms = () => {
    termsAndConditionsService.getTerms("pep").then(({ terms }) => {
      if (this._isMounted) {
        this.setState({ terms });
      }
    });
  };

  onCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  render() {
    const { terms } = this.state;

    if (!terms) {
      return <Loading></Loading>;
    }

    return (
      <Content>
        <FuiFlexContainer alignItems="center" direction="column" alley="medium">
          <FuiFlexItem>
            <FuiHeading>Terms and Conditions</FuiHeading>
          </FuiFlexItem>
          <div className="fuiFormControl fuiTextBox toc">
            <ReactMarkdown
              readOnly
              components={{
                link: (props) => (
                  <a href={props.href} target="_blank" rel="noreferrer">
                    {props.children}
                  </a>
                ),
              }}
            >
              {terms}
            </ReactMarkdown>
          </div>
          <FuiFlexItem justifyContent="space-evenly">
            <FuiButton
              className="fuiButtonWithIconTOCPage"
              color="primary"
              onClick={this.onCancel}
            >
              <FuiIcon type="chevronLeft" color="text" size="sm" />
              <span>Back</span>
            </FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }
}

TermsAndConditions.propTypes = {
  onCancel: PropTypes.func.isRequired,
};
