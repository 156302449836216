import React, { Component } from "react";
import {
  FuiFlexItem,
  FuiFlexContainer,
  FuiHeading,
} from "@forcuraco/forcura-ui-components";
import Content from "../shared/Content";

export default class Home extends Component {
  render() {
    return (
      <Content>
        <FuiFlexContainer alignItems="center" direction="column" alley="medium">
          <FuiFlexItem>
            <FuiHeading size="md">
              <div style={{ marginBottom: "36px" }}>
                <div className="message-header">Oops!</div>
                <br />
                It looks like you came here by mistake. <br />
                You can visit us at our<span>&nbsp;</span>
                <a href="https://www.forcura.com/">home page</a>.
              </div>
            </FuiHeading>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }
}
