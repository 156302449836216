import React from "react";
import {
  FuiFlexContainer,
  FuiFlexItem,
  FuiIcon,
} from "@forcuraco/forcura-ui-components";

import "./MFAMessagingChangesInfo.scss";

const MFAMessagingChangesInfo = () => {
  return (
    <FuiFlexContainer
      direction="row"
      justifyContent="center"
      grow={false}
      className="MFAMessageContainer"
      alley="large"
    >
      <FuiFlexItem className="MFAMessageIcon">
        <FuiIcon color="#2893a1" size="sm" type="circleInfo" />
      </FuiFlexItem>
      <FuiFlexItem className="MFAMessageText">
        Multi-Factor Authentication (MFA) is required to use Forcura Circle. By
        entering your mobile number and clicking “Sign In” you agree to receive
        one-time passcodes via text message with each login attempt. Message and
        data rates may apply.
      </FuiFlexItem>
    </FuiFlexContainer>
  );
};

export default MFAMessagingChangesInfo;
