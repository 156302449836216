export const validatePhoneNumber = (fieldName, fieldValue) => {
  const value = String(fieldValue);
  if (value === "null" || value.trim() === "") {
    return `${fieldName} is required`;
  }
  /*
     * phone formats
      (123) 456 - 7890
      123 - 456 - 7890
      123.456.7890
      1234567890
      075 - 63546725
     */
  const phoneFormat =
    /^\(?([2-9][0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g;

  if (!phoneFormat.test(value)) {
    return "Please provide a 10-digit US phone number.";
  }

  if (value.trim().length < 10) {
    return `${fieldName} needs to be at least ten characters.`;
  }
  return null;
};

export const escapeFormattedMobileNumber = (mobileNumber) => {
  return mobileNumber?.replace(/\D/g, "");
};

export const formatMobileNumber = (mobileNumber) => {
  // returns (###) ###-####
  if (!mobileNumber) {
    return undefined;
  }

  let formattedInput = mobileNumber.replace(/\D/g, "");
  const size = formattedInput.length;
  if (size > 0) {
    formattedInput = `(${formattedInput}`;
  }
  if (size > 3) {
    formattedInput = `${formattedInput.slice(0, 4)}) ${formattedInput.slice(
      4,
      11,
    )}`;
  }
  if (size > 6) {
    formattedInput = `${formattedInput.slice(0, 9)}-${formattedInput.slice(9)}`;
  }
  return formattedInput;
};
