import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import { authService } from "../../services/AuthService";
import Content from "./Content";
import Loading from "./Loading";

export default class Error extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);
    const errorId = params.get("errorId"); // we use this id when we need to lookup an error in the idsrv context.
    const errorRequestId = params.get("requestId");

    this.state = {
      error: props.error,
      errorDescription: props.errorDescription,
      loading: errorId || false,
      requestId: props.requestId || errorRequestId,
    };

    if (errorId) {
      authService.getError(errorId, (data) => {
        this.setState({ ...data, loading: false });
      });
    }
  }

  componentDidMount() {
    this.subscription = authService.subscribe(this.onStateChanged);

    const { loginState } = authService.getState();
    this.setState({ ...loginState });
  }

  componentWillUnmount() {
    authService.unsubscribe(this.subscription);
  }

  shouldComponentUpdate = () => {
    return true;
  };

  onStateChanged = () => {
    const { loginState } = authService.getState();
    this.setState({ ...loginState });
  };

  render() {
    const { errorDescription, error, requestId, loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Content>
        <FuiFlexContainer alignItems="center" direction="column" alley="medium">
          <FuiFlexItem>
            <FuiHeading>We have had an anomaly!</FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiHeading size="lg">
              <span>{errorDescription}</span>
            </FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiIcon color="#f2545b" size="xl" type="triangleExclamation" />
          </FuiFlexItem>
          <FuiFlexItem className="following">
            <FuiHeading size="sm">
              <span>{error}</span>
            </FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem className="following">
            <FuiHeading size="xs">
              <span>{requestId}</span>
            </FuiHeading>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }
}

Error.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  error: PropTypes.string,
  errorDescription: PropTypes.string,
  requestId: PropTypes.string,
};

Error.defaultProps = {
  error: undefined,
  errorDescription: undefined,
  requestId: undefined,
};
