import React from "react";
import { PropTypes } from "prop-types";
import { FuiFlexItem } from "@forcuraco/forcura-ui-components";

export default function PasswordRequirements(props) {
  const { isPasswordReset } = props;

  const passwordText = isPasswordReset
    ? "Your password must be at least 8 characters and cannot be the same as your 10 previous passwords."
    : "Your password must be at least 8 characters.";

  return (
    <FuiFlexItem grow={false} className="passwordRequirementsContainer">
      <FuiFlexItem direction="column">
        <FuiFlexItem>
          <span>{passwordText}</span>
        </FuiFlexItem>
        <FuiFlexItem>
          <span>It also must contain at least three of the following:</span>
        </FuiFlexItem>
        <FuiFlexItem>
          <ul>
            <li key="upper">One uppercase character</li>
            <li key="lower">One lowercase character</li>
            <li key="number">One number</li>
            <li key="special">One special character like $, % or !</li>
          </ul>
        </FuiFlexItem>
      </FuiFlexItem>
    </FuiFlexItem>
  );
}

PasswordRequirements.propTypes = {
  isPasswordReset: PropTypes.bool,
};

PasswordRequirements.defaultProps = {
  isPasswordReset: false,
};
