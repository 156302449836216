import { Paths } from "../helpers/Constants";

class TermsAndConditionsService {
  getTerms(clientId) {
    return (
      fetch(`${Paths.ApiTermsAndConditionsUrl}/${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
        .then((resp) => resp.json())
        .then((payload) => {
          return payload;
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.warn(error))
    );
  }
}

export const termsAndConditionsService = new TermsAndConditionsService();
