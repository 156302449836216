import React, { Component } from "react";
import PropTypes from "prop-types";
import { FuiFlexItem, FuiHeading } from "@forcuraco/forcura-ui-components";
import Content from "../../shared/Content";

export default class ExpiredToken extends Component {
  render() {
    const { applicationUrl, errorDescription } = this.props;

    return (
      <Content>
        <FuiFlexItem>
          <FuiHeading>
            <span>{errorDescription}</span>
          </FuiHeading>
        </FuiFlexItem>
        <FuiFlexItem>
          <FuiHeading size="sm">
            <span>
              Already a registered user?
              <span>
                {" "}
                <span />
              </span>
              <a href={applicationUrl}>Sign In</a>.
            </span>
          </FuiHeading>
        </FuiFlexItem>
      </Content>
    );
  }
}

ExpiredToken.propTypes = {
  applicationUrl: PropTypes.string.isRequired,
  errorDescription: PropTypes.string.isRequired,
};
