import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiCheckBox,
  FuiFlexItem,
  FuiHeading,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import UserAgreement from "../shared/UserAgreement";

export default class UserAgreementAcceptanceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userAgreementToShow: null,
    };
  }

  showUserAgreement = (userAgreementToShow) => {
    this.setState({ userAgreementToShow });
  };

  hideAccountUserAgreement = () => {
    this.showUserAgreement(undefined);
  };

  showAccountUserAgreement = (accountId) => {
    this.showUserAgreement(accountId);
  };

  updateAgreedList = (accountId, e) => {
    const { userAgreementsAccepted, onUpdateAcceptance } = this.props;

    if (e.target.checked) {
      onUpdateAcceptance([...userAgreementsAccepted, accountId]);
    } else {
      const remainingList = userAgreementsAccepted.filter(
        (a) => a !== accountId,
      );
      onUpdateAcceptance(remainingList);
    }
  };

  render() {
    const { userAgreements, userAgreementsAccepted, wrap } = this.props;
    const { userAgreementToShow } = this.state;

    if (userAgreementToShow) {
      const accountName = userAgreements.find(
        (ua) => ua.accountId === userAgreementToShow,
      ).accountName;
      return (
        <UserAgreement
          accountId={userAgreementToShow}
          accountName={accountName}
          onClose={this.hideAccountUserAgreement}
        ></UserAgreement>
      );
    }

    const content = userAgreements.map((ua) => (
      <FuiFlexItem className="termsRow" key={`${ua.accountId}-row`}>
        <FuiCheckBox
          id={`userAgreementCheckBox-${ua.accountId}`}
          key={ua.accountId}
          onChange={(e) => this.updateAgreedList(ua.accountId, e)}
          checked={userAgreementsAccepted.indexOf(ua.accountId) >= 0}
        />
        <FuiHeading
          size="xs"
          className={"termsCheckBox"}
          key={`${ua.accountId}-checkbox`}
        >
          I have read and agree to the {ua.accountName}&nbsp;
          <button className="customLink" onClick={() => {this.showAccountUserAgreement(ua.accountId)}}>
            <span>
              User Agreement
            </span>
          </button>
        </FuiHeading>
      </FuiFlexItem>
    ));

    if (wrap) {
      return (
        <FuiFlexContainer
          className="userAgreementAcceptanceList"
          alignItems="flex-start"
          direction="column"
          alley="small"
        >
          {content}
        </FuiFlexContainer>
      );
    } else {
      return <>{content}</>;
    }
  }
}

UserAgreementAcceptanceList.propTypes = {
  userAgreements: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.number.isRequired,
      accountName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  userAgreementsAccepted: PropTypes.arrayOf(PropTypes.number).isRequired,
  onUpdateAcceptance: PropTypes.func.isRequired,
  wrap: PropTypes.bool,
};

UserAgreementAcceptanceList.defaultProps = {
  wrap: true,
};
