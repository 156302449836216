import React from "react";
import {
  FuiFlexItem,
  FuiIcon,
  FuiTooltip,
  FuiButtonNoOutline,
} from "@forcuraco/forcura-ui-components";
import PasswordRequirements from "./PasswordRequirements";

export default function PasswordRequirementsToolTip() {
  const content = <PasswordRequirements />;

  return (
    <FuiFlexItem className="requirementsItem">
      <FuiIcon color="#32bfc6" size="sm" type="circleInfo" />
      <FuiTooltip enableOverflow="viewport" content={content}>
        <FuiButtonNoOutline className="passwordRequirements">
          Password Requirements
        </FuiButtonNoOutline>
      </FuiTooltip>
    </FuiFlexItem>
  );
}
