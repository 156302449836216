import React, { Component } from "react";
import {
  FuiFlexItem,
  FuiHeading,
  FuiLogo,
} from "@forcuraco/forcura-ui-components";
import AuthLogoutService from "../../services/AuthLogoutService";
import Content from "../shared/Content";

const authLogoutService = new AuthLogoutService();

export default class Logout extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const logoutId = params.get("logoutId");

    this.state = {
      loading: true,
    };

    authLogoutService.logout(this.updateState, logoutId);
  }

  updateState = (data) => {
    this.setState({ ...data, loading: false });
  };

  redirectUser = (url) => {
    window.location.replace(url);
    return <></>;
  };

  render() {
    const {
      state: { postLogoutRedirectUri, loading },
      redirectUser,
    } = this;

    if (postLogoutRedirectUri) {
      return redirectUser(postLogoutRedirectUri);
    }

    let message = "You have been logged out.";
    if (loading) {
      message = "Processing your request...";
    }

    return (
      <Content>
        <FuiFlexItem>
          <FuiHeading>{message}</FuiHeading>
        </FuiFlexItem>
        <FuiFlexItem>
          <FuiLogo size="md" type="colorLogoMark" />
        </FuiFlexItem>
      </Content>
    );
  }
}
