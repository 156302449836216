import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import { authService } from "../../services/AuthService";
import Loading from "../shared/Loading";
import Content from "../shared/Content";

export default class Impersonate extends Component {
  constructor(props) {
    super(props);

    const {
      props: { location },
    } = this;

    const params = new URLSearchParams(location.search);
    const returnUrl = params.get("returnUrl");
    const userId = params.get("userId");

    this.state = {
      returnUrl,
      userId,
      loading: true,
    };
  }

  componentDidMount() {
    this.subscription = authService.subscribe(this.onStateChanged);
    this.impersonate();
  }

  componentWillUnmount() {
    authService.unsubscribe(this.subscription);
  }

  onStateChanged = () => {
    const { loginState } = authService.getState();
    if (loginState) {
      this.setState({ ...loginState });
    } //something went wrong during impersonation, lets just show the error.
    else {
      this.setState({ loading: false });
    }
  };

  impersonate() {
    const { userId, returnUrl } = this.state;

    if (!userId || !returnUrl) {
      this.setState({ loading: false });

      return;
    }

    authService.impersonate(userId, returnUrl);
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Content>
        <FuiFlexContainer alignItems="center" direction="column" alley="medium">
          <FuiFlexItem>
            <FuiHeading>Impersonation Failed!</FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiIcon color="#f2545b" size="xl" type="triangleExclamation" />
            <span
              style={{ color: "#f2545b", margin: "auto", paddingLeft: "10px" }}
            >
              You have tried to impersonate a user you don&apos;t have access
              to.
            </span>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }
}

Impersonate.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};
