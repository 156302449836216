import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../shared/Loading";
import NameAndNumber from "./pages/NameAndNumber";
import PasswordAndPhone from "./pages/PasswordAndPhone";
import Error from "../shared/Error";
import ExpiredToken from "./pages/ExpiredToken";

export default class Register extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");

    const state = {
      redirect: "",
      page: 1,
      firstName: "",
      lastName: "",
      npi: "",
      password: "",
      mobilePhoneNumber: "",
      token,
      loading: true,
      npiError: null,
      npiAndNameError: null,
    };

    this.state = state;
    this.validateRegisterToken(state);
  }

  validateRegisterToken = (registerRequest) => {
    const { registerService } = this.props;
    registerService.validateRegisterToken(registerRequest);
  };

  submitRegister = (registerRequest) => {
    const { registerService } = this.props;
    registerService.register(registerRequest);
  };

  componentDidMount() {
    const { registerService } = this.props;
    this.subscription = registerService.subscribe(this.handleTokenCheck);
    const { registerState } = registerService.getState();
    this.setState({ ...registerState, currentStep: 1 });
  }

  componentWillUnmount() {
    const { registerService } = this.props;
    registerService.unsubscribe(this.subscription);
  }

  shouldComponentUpdate = () => {
    return true;
  };

  onStateChanged = () => {
    const { registerService } = this.props;
    const { state } = registerService.getState();
    this.setState({ ...state });
  };

  login = () => {
    this.setState({
      redirect: "login",
    });
  };

  handleTokenCheck = () => {
    const { registerService } = this.props;
    const { registerState } = registerService.getState();
    this.setState({
      ...registerState,
    });
  };

  submit = () => {
    const { page } = this.state;

    if (page === 2) {
      const clone = { ...this.state };
      this.setState({ loading: true })
      this.submitRegister(clone);
    }

    this.setState({
      page: page + 1,
    });
  };

  back = () => {
    const { registerService } = this.props;
    const { page } = this.state;

    registerService.resetState();

    this.setState({
      page: page - 1,
    });
  };

  render() {
    const {
      state: {
        page,
        redirect,
        isErrored,
        error,
        errorDescription,
        requestId,
        loading,
        isExpired,
        applicationUrl,
        linkingToExistingProviderAccount,
      },
      props: { renderRedirect, location, registerService },
    } = this;

    const totalSteps = 2;

    if (loading) {
      return <Loading />;
    }

    if (isErrored) {
      if (isExpired) {
        return (
          <ExpiredToken
            applicationUrl={applicationUrl}
            errorDescription={errorDescription}
          />
        );
      }

      return (
        <Error
          error={error}
          errorDescription={errorDescription}
          requestId={requestId}
          location={location}
        />
      );
    }

    if (redirect !== "") {
      return renderRedirect(redirect);
    }

    if (page === 1) {
      return (
        <NameAndNumber
          submitRegister={this.submitRegister}
          onCancel={this.login}
          registerService={registerService}
          totalSteps={totalSteps}
          location={location}
        />
      );
    }

    if (page === 2) {
      if (linkingToExistingProviderAccount) {
        return renderRedirect("linkedToExistingProvider");
      }

      return <PasswordAndPhone onSubmit={this.submit} onCancel={this.back} registerService={registerService} />;
    }

    return <></>
  }
}

Register.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  renderRedirect: PropTypes.func.isRequired,
};
