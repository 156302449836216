import React, { Component } from "react";
import PropTypes from "prop-types";
import { FuiButtonNoOutline } from "@forcuraco/forcura-ui-components";

export default class Steps extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    const { totalSteps, currentStep } = this.props;

    const elements = [];
    for (let step = 1; step <= totalSteps; step += 1) {
      elements.push(
        <FuiButtonNoOutline
          key={step}
          className="dot"
          isDisabled={step !== currentStep}
        >
          •
        </FuiButtonNoOutline>,
      );
    }

    return <div>{elements}</div>;
  };
}

Steps.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};
