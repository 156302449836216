export default class AuthLogoutService {
  logout = (callBack, logoutId) => {
    fetch(`/Login/Logout/${logoutId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((responseData) => {
        callBack(responseData);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn(error));
  };
}
