import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiOverlay,
  FuiModal,
  FuiModalHeader,
  FuiHeading,
  FuiModalBody,
  FuiFlexContainer,
  FuiFlexItem,
  FuiTextBox,
  FuiModalFooter,
  FuiButton,
} from "@forcuraco/forcura-ui-components";

export default class MobileNumberConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpNumber: '',
      otpNumberError: []
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      case "otpNumber":
        this.setState({ otpNumber: e.target.value });
        break;
      default:
        break;
    }
  };

  confirm = () => {
    const { otpNumber } = this.state;
    const { mobilePhoneNumber, token, confirmMobileNumber, onSuccessfulMobileNumberConfirmation, userId, clientId } = this.props;

    confirmMobileNumber({
      mobilePhoneNumber,
      token,
      otpNumber,
      userId,
      clientId
    }).then((resp) => {
      if (resp.ok) {
        onSuccessfulMobileNumberConfirmation();
        return;
      }

      this.setState({ otpNumberError: [`Passcode did not match`] });
    });
  };

  render() {
    const {
      confirm,
      onChange,
      state: { otpNumberError },
      props: { closeModal, displayModal },
    } = this;

    return (
      displayModal && (
        <FuiOverlay>
          <FuiModal onClose={closeModal}>
            <FuiModalHeader>
              <FuiHeading size="md">Confirm Mobile Number</FuiHeading>
            </FuiModalHeader>

            <FuiModalBody>
              <FuiFlexContainer direction="column" alley="none">
                <FuiFlexItem direction="column">
                  <label htmlFor="otpNumber" className="otpNumber-label">
                    Please confirm your number with the passcode we just sent.
                  </label>
                  <FuiTextBox
                    id="otpNumber"
                    autoComplete="off"
                    className="otpNumber"
                    name="otpNumber"
                    placeholder="Passcode"
                    onChange={onChange}
                    errors={otpNumberError}
                    tabIndex={1}
                  />
                </FuiFlexItem>
              </FuiFlexContainer>
            </FuiModalBody>

            <FuiModalFooter>
              <FuiFlexItem grow={false}>
                <FuiButton noFill color="primary" onClick={closeModal}>
                  Cancel
                </FuiButton>
              </FuiFlexItem>
              <FuiFlexItem grow={false}>
                <FuiButton color="primary" onClick={confirm}>
                  Confirm
                </FuiButton>
              </FuiFlexItem>
            </FuiModalFooter>
          </FuiModal>
        </FuiOverlay>
      )
    );
  }
}

MobileNumberConfirmationModal.propTypes = {
  mobilePhoneNumber: PropTypes.string.isRequired,
  displayModal: PropTypes.bool.isRequired,
  onSuccessfulMobileNumberConfirmation: PropTypes.func.isRequired,
  confirmMobileNumber: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  userId: PropTypes.string,
  clientId: PropTypes.string,
};
