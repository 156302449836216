import React, { Component } from "react";
import {
  FuiButton,
  FuiFlexContainer,
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiOverlay,
} from "@forcuraco/forcura-ui-components";
import Content from "./Content";

export default class UserAgreementextends extends Component {
  componentDidMount() {
    document.getElementById("userAgreementIframe").contentWindow.onload =
      () => {
        document.getElementById("userAgreementIframe").style.width = "100%";

        const img = document.getElementsByTagName("img")[0];
        if (img) {
          img.style.width = "100%";
        }
      };
  }

  render() {
    const { accountId, accountName, onClose } = this.props;
    //https://blog.devgenius.io/how-to-display-a-pdf-using-an-iframe-and-make-it-responsive-d3c3fcd3a460
    const url = `/accountuseragreement/${accountId}/view?date=${new Date().toLocaleString()}#view=fitH`;
    return (
      <FuiOverlay className="overrideBackground">
        <Content
          cardClassName="large-card"
          containerClassName="userAgreementContainer"
        >
          <FuiFlexContainer
            direction="column"
            alignItems="center"
            alley="large"
            grow={false}
          >
            <FuiHeading>Terms and Conditions</FuiHeading>
            <span>{accountName} User Agreement</span>
          </FuiFlexContainer>

          <FuiFlexItem grow={1} className="userAgreementReader">
            <div style={{ width: "100%", height: "100%" }}>
              <iframe
                id="userAgreementIframe"
                title="userAgreement"
                src={url}
                style={{ width: "100%", height: "100%" }}
                type="application/pdf"
              ></iframe>
            </div>
          </FuiFlexItem>

          <FuiFlexItem padding="bottom">
            <FuiButton onClick={onClose} className="userAgreementBackButton">
              <FuiIcon type="chevronLeft" color="text" size="sm" />
              <span>Back</span>
            </FuiButton>
          </FuiFlexItem>
        </Content>
      </FuiOverlay>
    );
  }
}
