import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiCard,
  FuiFlexContainer,
  FuiFlexItem,
  FuiLogo,
} from "@forcuraco/forcura-ui-components";
import { LoginLogosTypes } from "../../helpers/Constants";
import { ReactComponent as ForcuraCircleLogo }  from "../assets/ForcuraCircleLogo.svg";
import "./content.scss";
import authContext from "../../context/authContext";

class Content extends Component {
  static contextType = authContext;
  render() {
    const {
      props: { children, cardClassName, containerClassName },
      context: { loginLogoType },
    } = this;

    let logo;
    switch (loginLogoType.toUpperCase()) {
      case LoginLogosTypes.Circle:
        logo = <ForcuraCircleLogo className="fuiLogo circleLogo" />;
        break;
      default:
        logo = <FuiLogo size="sm" type="colorLogoHorizontal" />;
        break;
    }

    return (
      <FuiFlexContainer
        className="centered content-card"
        alignItems="center"
        alley="medium"
        grow
      >
        <FuiCard grow={false} className={cardClassName}>
          <FuiFlexContainer
            direction="column"
            alignItems="center"
            alley="medium"
            className={containerClassName}
          >
            <FuiFlexItem>{logo}</FuiFlexItem>
            {children}
          </FuiFlexContainer>
        </FuiCard>
      </FuiFlexContainer>
    );
  }
}

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element.isRequired])),
  ]).isRequired,
  cardClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default Content;
