import { Paths } from "../helpers/Constants";

export default class AccountUserAgreementService {
  doesUserAgreementExistForProvider(clientId) {
    return (
      fetch(
        `${
          Paths.AccountUserAgreementBase
        }/${clientId}/provider?date=${new Date().toLocaleString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      )
        .then((resp) => resp.json())
        // eslint-disable-next-line no-console
        .catch((error) => console.warn(error))
    );
  }

  getUserAgreementsForStaff(providerAccountId) {
    return (
      fetch(
        `${
          Paths.AccountUserAgreementBase
        }/${providerAccountId}/staff?date=${new Date().toLocaleString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      )
        .then((resp) => resp.json())
        // eslint-disable-next-line no-console
        .catch((error) => console.warn(error))
    );
  }
}

export const accountUserAgreementService = new AccountUserAgreementService();
