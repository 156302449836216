import React, { Component } from "react";
import {
  FuiFlexItem,
  FuiLoading,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import Content from "./Content";

export default class Loading extends Component {
  render() {
    return (
      <Content>
        <FuiFlexContainer
          grow
          direction="column"
          alignItems="center"
          alley="medium"
          className="loadingContainer"
        >
          <FuiFlexItem grow>
            <FuiLoading size="md" />
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }
}
