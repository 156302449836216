import React, { Component } from "react";
import {
  FuiFlexItem,
  FuiHeading,
  FuiButton,
} from "@forcuraco/forcura-ui-components";
import { registerService } from "../../services/RegisterService";
import Content from "../shared/Content";
import Steps from "../shared/Steps";
import Loading from "../shared/Loading";

export default class LinkedToExistingProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const { registerState } = registerService.getState();
    this.setState({ ...registerState, loading: false });
  }

  login = () => {
    const {
      state: { applicationUrl },
    } = this;

    window.location.replace(applicationUrl);
  };

  render() {
    const {
      state: { loading },
      login,
    } = this;

    if (loading) {
      return <Loading />;
    }

    return (
      <Content>
        <FuiFlexItem>
          <FuiHeading>
            You added a new relationship to your account, Please use your
            existing credentials to sign in.
          </FuiHeading>
        </FuiFlexItem>
        <FuiFlexItem>
          <Steps totalSteps={2} currentStep={2} />
        </FuiFlexItem>
        <FuiFlexItem>
          <FuiButton onClick={login}>Done</FuiButton>
        </FuiFlexItem>
      </Content>
    );
  }
}
