import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiPasswordBox,
  FuiTextBox,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import { validator } from '@forcuraco/forcura-ui-components';
import PasswordService from "../../services/PasswordService";
import {
  validatePhoneNumber,
  escapeFormattedMobileNumber,
  formatMobileNumber,
} from "../../helpers/PhoneNumberValidator";
import { accountUserAgreementService } from "../../services/AccountUserAgreementService";
import ExpiredToken from "./pages/ExpiredToken";
import Content from "../shared/Content";
import MFAMessagingChangesInfo from "../shared/MFAMessagingChangesInfo";
import PasswordRequirements from "../shared/PasswordRequirements";
import PasswordRequirementsToolTip from "../shared/PasswordRequirementsToolTip";
import Loading from "../shared/Loading";
import Error from "../shared/Error";
import MobileNumberConfirmationModal from "../shared/MobileNumberConfirmationModal";
import UserAgreementAcceptanceList from "../shared/UserAgreementAcceptanceList";

const ValidationConstraints = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'First Name is required'
    }
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'Last Name is required'
    }
  }
}

export default class StaffRegister extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");

    const state = {
      password: "",
      mobilePhoneNumber: undefined,
      mobilePhoneNumberError: [],
      passwordRulesMet: true,
      loading: true,
      token,
      displayMobileNumberConfirmationModal: false,
      userAgreements: [],
      userAgreementsAccepted: [],
      userAgreementToShow: null,
    };

    this.state = state;

    this.PasswordService = new PasswordService();

    this.validateRegisterToken(state);
  }

  onMouseDown = (e) => {
    e.preventDefault();
    this.onSubmit();
  };

  onSubmit = () => {
    const {
      state: { mobilePhoneNumber, password, firstName, lastName, token },
      props: { registerService }
    } = this;

    const phoneNumber = escapeFormattedMobileNumber(mobilePhoneNumber);
    const passwordRulesMet = this.PasswordService.validatePassword(password);
    const mobilePhoneNumberError = validatePhoneNumber("Mobile Number", phoneNumber);
    const validationErrors = validator.validate(this.state, ValidationConstraints, { format: "grouped" });
    if (validationErrors || !passwordRulesMet || mobilePhoneNumberError) {
      this.setState({
        firstNameError: validationErrors && validationErrors['firstName'],
        lastNameError: validationErrors && validationErrors['lastName'],
        mobilePhoneNumberError: mobilePhoneNumberError === null ? null : [mobilePhoneNumberError],
        passwordRulesMet
      });
      return;
    }

    this.setState({
      displayMobileNumberConfirmationModal: true,
    });

    registerService.sendOtpToConfirmMobileNumber(
      phoneNumber,
      password,
      firstName,
      lastName,
      token,
      "",
    );
  };

  closeMobileNumberConfirmationModal = () => {
    this.setState({
      displayMobileNumberConfirmationModal: false,
    });
  };

  validateRegisterToken = (registerRequest) => {
    const { registerService } = this.props;
    registerService.validateRegisterToken(registerRequest);
  };

  submitStaffRegister = () => {
    this.setState({ loading: true });
    const { registerService } = this.props;
    registerService.register(this.state);
  };

  componentDidMount() {
    const { registerService } = this.props;
    this.subscription = registerService.subscribe(this.onStateChanged);
  }

  onStateChanged = () => {
    const { registerService } = this.props;
    const { registerState } = registerService.getState();

    if (registerState.providerStaffInformation) {
      accountUserAgreementService
        .getUserAgreementsForStaff(
          registerState.providerStaffInformation.providerAccountId,
        )
        .then((r) => this.setState({ ...registerState, userAgreements: r }));
    } else {
      this.setState({ ...registerState });
    }
  };

  componentWillUnmount() {
    const { registerService } = this.props;
    registerService.unsubscribe(this.subscription);
  }

  mobilePhoneNumberOnBlur = () => {
    const { mobilePhoneNumber } = this.state;

    const phoneNumber = escapeFormattedMobileNumber(mobilePhoneNumber);
    const mobilePhoneNumberError = validatePhoneNumber(
      "Mobile Number",
      phoneNumber,
    );
    this.setState({
      mobilePhoneNumberError:
        mobilePhoneNumberError === null ? null : [mobilePhoneNumberError],
    });
  };

  passwordOnBlur = () => {
    const { password } = this.state;

    const passwordRulesMet = this.PasswordService.validatePassword(password);
    this.setState({
      passwordRulesMet,
    });
  };

  firstNameOnBlur = (event) => {
    const value = event.target.value || null;
    const errors = validator.validateSingle(value, ValidationConstraints['firstName']);
    this.setState({
      firstNameError: errors
    });
  };

  lastNameOnBlur = (event) => {
    const value = event.target.value || null;
    const errors = validator.validateSingle(value, ValidationConstraints['lastName']);
    this.setState({
      lastNameError: errors
    });
  };

  onChange = (e) => {
    switch (e.target.name) {
      case "firstName":
        this.setState({
          firstName: e.target.value,
        });
        break;

      case "lastName":
        this.setState({
          lastName: e.target.value,
        });
        break;

      case "mobilePhoneNumber":
        this.setState({
          mobilePhoneNumber: escapeFormattedMobileNumber(e.target.value),
        });
        break;

      default:
        break;
    }
  };

  onPasswordChange = (value) => {
    this.setState({
      password: value,
    });
  };

  onPasswordVisibilityChange = (isVisible) => {
    this.setState({
      revealPassword: isVisible,
    });
  };

  declineInvite = () => {
    this.setState({
      accepted: false,
      declined: true,
    });
  };

  acceptInvite = () => {
    const { registerService } = this.props;
    const {
      state: { mobilePhoneNumber, password, firstName, lastName, token },
    } = this;
    registerService.register({
      firstName,
      lastName,
      mobilePhoneNumber,
      password,
      token,
    });
    this.setState({
      accepted: true,
    });
  };

  login = () => {
    this.setState({
      redirect: "login",
    });
  };

  renderPassword = (
    passwordRulesMet,
    onChange,
    onBlur,
    revealPassword,
    onPasswordVisibilityChange,
    value,
  ) => {
    let errorText = null;
    if (!passwordRulesMet) {
      const errorUi = <PasswordRequirements />;

      errorText = [errorUi];
    }

    return (
      <FuiFlexItem className="registrationInput">
        <FuiPasswordBox
          key={2}
          name="password"
          placeholder="Password"
          className="inputbox"
          onChange={onChange}
          errors={errorText}
          onBlur={onBlur}
          revealPassword={revealPassword}
          onPasswordVisibilityChange={onPasswordVisibilityChange}
          value={value || ""}
        />
      </FuiFlexItem>
    );
  };

  updateAgreedList = (accountIds) => {
    this.setState({ userAgreementsAccepted: accountIds });
  };

  render() {
    const {
      state: {
        mobilePhoneNumberError,
        linkingToExistingProviderAccount,
        existingProviderAccountName,
        passwordRulesMet,
        loading,
        firstNameError,
        lastNameError,
        isExpired,
        applicationUrl,
        errorDescription,
        requestId,
        error,
        isErrored,
        accepted,
        redirect,
        declined,
        password,
        revealPassword,
        displayMobileNumberConfirmationModal,
        mobilePhoneNumber,
        userAgreements,
        userAgreementsAccepted,
        token
      },
      onChange,
      onPasswordChange,
      onPasswordVisibilityChange,
      firstNameOnBlur,
      lastNameOnBlur,
      passwordOnBlur,
      mobilePhoneNumberOnBlur,
      declineInvite,
      acceptInvite,
      props: { location, renderRedirect, registerService },
    } = this;

    if (loading) {
      return <Loading />;
    }

    if (redirect && redirect !== "") {
      return renderRedirect(redirect, applicationUrl);
    }

    if (isExpired) {
      return (
        <ExpiredToken
          applicationUrl={applicationUrl}
          errorDescription={errorDescription}
        />
      );
    }

    if (isErrored) {
      return (
        <Error
          error={error}
          errorDescription={errorDescription}
          requestId={requestId}
          location={location}
        />
      );
    }

    if (accepted || declined) {
      const message = accepted
        ? `You have joined ${existingProviderAccountName}.`
        : `${existingProviderAccountName} invite has been declined.`;

      return (
        <Content>
          <FuiFlexItem>
            <span className="textAlingCenter">{message}</span>
          </FuiFlexItem>
          <FuiFlexItem padding="top">
            <FuiButton onClick={() => window.location.replace(applicationUrl)}>
              login
            </FuiButton>
          </FuiFlexItem>
        </Content>
      );
    }

    if (linkingToExistingProviderAccount) {
      return (
        // Does this need to accomodate the registration as well?
        <Content>
          <FuiFlexItem>
            <span className="textAlingCenter">
              Would you like to join {existingProviderAccountName}?
            </span>
          </FuiFlexItem>
          <FuiFlexItem padding="top">
            <FuiButton noFill onClick={declineInvite}>
              No
            </FuiButton>
            <FuiButton onClick={acceptInvite}>Yes</FuiButton>
          </FuiFlexItem>
        </Content>
      );
    }

    const phoneNumber = formatMobileNumber(mobilePhoneNumber);

    return (
      <Content>
        <FuiFlexContainer
          className="contentsContainer"
          alignItems="center"
          direction="column"
          alley="medium"
        >
          <FuiFlexItem>
            <FuiHeading>Register</FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem className="registrationInput">
            <FuiTextBox
              autoComplete="off"
              name="firstName"
              className="inputbox"
              placeholder="First Name"
              onChange={onChange}
              errors={firstNameError}
              onBlur={firstNameOnBlur}
            />
          </FuiFlexItem>
          <FuiFlexItem className="registrationInput">
            <FuiTextBox
              className="inputbox"
              autoComplete="off"
              name="lastName"
              placeholder="Last Name"
              onChange={onChange}
              errors={lastNameError}
              onBlur={lastNameOnBlur}
            />
          </FuiFlexItem>
          {this.renderPassword(
            passwordRulesMet,
            onPasswordChange,
            passwordOnBlur,
            revealPassword,
            onPasswordVisibilityChange,
            password,
          )}
          <PasswordRequirementsToolTip />
          <FuiFlexItem className="registrationInput">
            <FuiTextBox
              className="inputbox"
              autoComplete="off"
              name="mobilePhoneNumber"
              placeholder="Mobile Number"
              onChange={onChange}
              value={phoneNumber || ""}
              errors={mobilePhoneNumberError}
              onBlur={mobilePhoneNumberOnBlur}
            />
          </FuiFlexItem>
          <MFAMessagingChangesInfo />
          {userAgreements.length > 0 && (
            <UserAgreementAcceptanceList
              userAgreements={userAgreements}
              onUpdateAcceptance={this.updateAgreedList}
              userAgreementsAccepted={userAgreementsAccepted}
            ></UserAgreementAcceptanceList>
          )}
          <FuiFlexItem>
            <FuiButton
              color="primary"
              onMouseDown={this.onMouseDown}
              disabled={userAgreementsAccepted.length !== userAgreements.length}
            >
              Sign In
            </FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
        <MobileNumberConfirmationModal
          displayModal={displayMobileNumberConfirmationModal}
          onSuccessfulMobileNumberConfirmation={this.submitStaffRegister}
          confirmMobileNumber={registerService.confirmMobileNumber}
          closeModal={this.closeMobileNumberConfirmationModal}
          mobilePhoneNumber={mobilePhoneNumber || ""}
          token={token}
        />
      </Content>
    );
  }
}

StaffRegister.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  renderRedirect: PropTypes.func.isRequired,
};
