class RegisterServiceProviderStaffService {
  constructor() {
    this.callbacks = [];
    this.nextSubscriptionId = 6000;
    this.registerState = {};
    this.errorState = {};
  }

  validateRegisterToken(loginRequest) {
    fetch(`/registerproviderStaff`, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      credentials: "include",
      body: JSON.stringify(loginRequest),
    })
      .then((resp) => resp.json())
      .then((payload) =>
        this.updateRegisterState({
          ...loginRequest,
          ...payload,
          loading: false
        }),
      );
  }

  register(registerRequest) {
    fetch(`/registerproviderStaff`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      credentials: "include",
      body: JSON.stringify({
        firstName: registerRequest.firstName,
        lastName: registerRequest.lastName,
        npi: registerRequest.npi,
        password: registerRequest.password,
        mobilePhoneNumber: registerRequest.mobilePhoneNumber,
        timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
        browserTime: new Date(),
        token: registerRequest.token,
        returnUrl: registerRequest.returnUrl,
      }),
    })
      .then((resp) => resp.json())
      .then((payload) =>
        this.handleRegisterResponse({
          ...registerRequest,
          ...payload,
        }),
      )
      // eslint-disable-next-line no-console
      .catch((error) => console.warn(error));
  }

  handleRegisterResponse(registerResponse) {
    if (registerResponse.isAuthenticated) {
      window.location.replace(
        `${registerResponse.applicationUrl}?register=true`,
      );
    } else {
      this.updateRegisterState({
        ...registerResponse,
        loading: false,
        applicationUrl: `${registerResponse.applicationUrl}?register=true`,
      });
    }
  }

  sendOtpToConfirmMobileNumber(
    mobilePhoneNumber,
    password,
    firstName,
    lastName,
    token,
    otpNumber,
  ) {
    fetch(`/registerprovider/sendOtpToConfirmMobileNumber`, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      credentials: "include",
      body: JSON.stringify({
        firstName,
        lastName,
        token,
        otpNumber,
        mobilePhoneNumber,
      }),
    })
      .then((resp) => resp.json())
      .then((responseData) => {
        this.updateRegisterState({
          ...responseData,
          mobilePhoneNumber,
          password,
          loading: false,
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn(error));
  }

  confirmMobileNumber(confirmMobileNumberRequest) {
    return fetch(`/registerprovider/confirmMobileNumber`, {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      credentials: "include",
      body: JSON.stringify(confirmMobileNumberRequest),
    });
  }

  updateRegisterState(newState) {
    this.registerState = newState;
    this.notifySubscribers();
  }

  getState() {
    return {
      registerState: { ...this.registerState },
    };
  }

  resetState() {
    this.registerState = {};
  }

  subscribe(callback) {
    this.callbacks.push({
      callback,
      // eslint-disable-next-line no-plusplus
      subscription: this.nextSubscriptionId++,
    });
    return this.nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this.callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false },
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      );
    }

    this.callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this.callbacks.length; i += 1) {
      const { callback } = this.callbacks[i];
      callback();
    }
  }
}

export const registerServiceProviderStaffService = new RegisterServiceProviderStaffService();