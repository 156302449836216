import React, { Component } from "react";
import { Redirect, BrowserRouter, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import PropsRoute from "./components/shared/PropsRoute";
import Error from "./components/shared/Error";
import Login from "./components/login/Login";
import OtpLogin from "./components/login/OtpLogin";
import Logout from "./components/logout/Logout";
import ChangePassword from "./components/changePassword/changePassword";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import ResetPassword from "./components/resetPassword/ResetPassword";
import Register from "./components/register/Register";
import LinkedToExistingProvider from "./components/register/LinkedToExistingProvider";
import StaffRegister from "./components/register/StaffRegister";
import Home from "./components/home/Home";
import Impersonate from "./components/impersonate/Impersonate";
import { LoginLogosTypes } from "./helpers/Constants";
import { registerService } from "./services/RegisterService"
import { registerPhysicianGroupUserService } from "./services/RegisterPhysicianGroupUserService"
import { registerServiceProviderStaffService } from "./services/RegisterServiceProviderStaffService"
import "./App.scss";

export default class App extends Component {
  renderRedirect = (target, returnUrl, userId, clientId, token) => {
    switch (target) {
      case "login":
        return (
          <Redirect
            to={`/user/login?ReturnUrl=${encodeURIComponent(returnUrl)}`}
          />
        );

      case "otplogin":
        return (
          <Redirect
            to={`/user/otpLogin?ReturnUrl=${encodeURIComponent(returnUrl)}`}
          />
        );

      case "password":
        return (
          <Redirect
            to={`/user/password?ReturnUrl=${encodeURIComponent(returnUrl)}&userId=${userId}&clientId=${clientId}&token=${token}`}
          />
        );

      case "forgotPassword":
        return <Redirect to='/user/forgotpassword' />;

      case "resetPassword":
        return <Redirect to='/user/resetpassword' />;

      case "register":
        return <Redirect to='/user/register' />;

      case "linkedToExistingProvider":
        return <Redirect to='/user/linkedtoexistingprovider' />;

      case "error":
        return <Redirect to='/user/error' />;

      default:
        return <Redirect to="/" />;
    }
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PropsRoute
            path='/user/login'
            component={Login}
            renderRedirect={this.renderRedirect}
          />
          <PropsRoute path='/user/impersonate' component={Impersonate} />
          <PropsRoute
            path='/user/otpLogin'
            component={OtpLogin}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
          />
          <PropsRoute
            path='/user/password'
            component={ChangePassword}
            renderRedirect={this.renderRedirect}
          />
          <PropsRoute
            path='/user/forgotpassword'
            component={ForgotPassword}
            renderRedirect={this.renderRedirect}
            email={this.email}
          />
          <PropsRoute
            path='/user/resetpassword'
            component={ResetPassword}
            renderRedirect={this.renderRedirect}
          />
          <PropsRoute
            path='/user/register'
            component={Register}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
            registerService={registerService}
          />
          <PropsRoute
            path='/user/linkedtoexistingprovider'
            component={LinkedToExistingProvider}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
          />
          <PropsRoute
            path='/user/registerproviderstaff'
            component={StaffRegister}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
            registerService={registerServiceProviderStaffService}
          />
          <PropsRoute
            path='/user/registerphysiciangroup/PhysicianGroupAdmin'
            component={StaffRegister}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
            registerService={registerPhysicianGroupUserService}
          />
          <PropsRoute
            path='/user/registerphysiciangroup/PhysicianGroupStaff'
            component={StaffRegister}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
            registerService={registerPhysicianGroupUserService}
          />
          <PropsRoute
            path='/user/registerphysiciangroup/PhysicianGroupProvider'
            component={Register}
            renderRedirect={this.renderRedirect}
            loginLogoType={LoginLogosTypes.Circle}
            registerService={registerPhysicianGroupUserService}
          />
          <PropsRoute
            path='/user/logout'
            component={Logout}
          />
          <PropsRoute
            path='/user/error'
            component={Error}
            requestId={this.requestId}
            error={this.error}
            errorDescription={this.errorDescription}
          />
          <PropsRoute exact path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};
