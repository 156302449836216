import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
} from "@forcuraco/forcura-ui-components";
import AcceptUserAgreements from "./AcceptUserAgreements";
import { authOtpService } from "../../services/AuthOtpService";
import Loading from "../shared/Loading";
import Content from "../shared/Content";
import FormInput from "../shared/FormInput";
import Error from "../shared/Error";

export default class OtpLogin extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(props.location.search);

    let isErrored = false;
    let error = null;
    let deviceId = localStorage.getItem("deviceId");
    let errorDescription = null;
    const returnUrl = params.get("ReturnUrl");
    const token = params.get("token");

    if (!returnUrl || returnUrl.length === 0) {
      isErrored = true;
      error = "returnUrl_required";
      errorDescription = "Return url is a required parameter";
    }

    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceId", deviceId);
    }

    this.state = {
      deviceId,
      isErrored,
      error,
      errorDescription,
      returnUrl,
      token,
      browser: navigator.userAgent,
      loading: true,
      isResendOtpDisabled: false
    };
  }

  submitLogin = (loginRequest) => {
    authOtpService.authenticateOtp(loginRequest);
  };

  componentDidMount() {
    this.subscription = authOtpService.subscribe(this.onStateChanged);
    const { token } = this.state;

    authOtpService.sendOtp(token, this.state);
  }

  componentWillUnmount() {
    authOtpService.unsubscribe(this.subscription);
  }

  onStateChanged = () => {
    const { loginState } = authOtpService.getState();
    this.setState({ ...loginState });
  };

  onChange = (e) => {
    switch (e.target.name) {
      case "otp":
        this.setState({
          otpNumber: e.target.value,
        });
        break;
      default:
        break;
    }
  };

  resendOnClick = () => {
    const {
      state: { token, deviceId, browser, returnUrl },
    } = this;

    this.setState({ isResendOtpDisabled: true });
    authOtpService.sendOtp(token, {
      deviceId,
      browser,
      resend: true,
      returnUrl,
    });

    this.enableResendOtpButton(5000);
  };

  enableResendOtpButton = (delay) => {
    setTimeout(() => {
      this.setState({ isResendOtpDisabled: false });
    }, delay);
  };

  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.onChange(e);
      this.onClick();
    }
  };

  redirect = () => {
    const { returnUrl } = this.state;
    window.location.replace(returnUrl);
  };

  onClick = () => {
    const { otpNumber, deviceId, returnUrl, token, browser } = this.state;

    if (otpNumber) {
      this.submitLogin({
        otpNumber,
        loading: true,
        deviceId,
        returnUrl,
        token,
        browser,
      });
    }
  };

  onAgreementsAccepted = () => {
    const { mustAcceptUserAgreements } = this.state;

    const acceptedUserAgreements = mustAcceptUserAgreements.map((m) => {
      return m.id;
    });

    this.submitLogin({
      ...this.state,
      loading: true,
      acceptedUserAgreements,
    });
  };

  render() {
    const {
      state: {
        loading,
        errorDescription,
        isErrored,
        error,
        requestId,
        isBasicAuthRequired,
        sentOtp,
        mustAcceptUserAgreements,
        isResendOtpDisabled
      },
      props: { location },
      onChange,
      onClick,
      resendOnClick,
      redirect,
    } = this;

    if (loading) {
      return <Loading />;
    }

    if (isErrored) {
      return (
        <Error
          error={error}
          errorDescription={errorDescription}
          requestId={requestId}
          location={location}
        />
      );
    }

    if (isBasicAuthRequired) {
      localStorage.removeItem("deviceId");
      return redirect();
    }

    if (mustAcceptUserAgreements) {
      return (
        <Content>
          <AcceptUserAgreements
            userAgreements={mustAcceptUserAgreements}
            onAgreementsAccepted={this.onAgreementsAccepted}
          ></AcceptUserAgreements>
        </Content>
      );
    }

    return (
      <Content>
        <FuiFlexItem>
          <FuiHeading>Sign In</FuiHeading>
        </FuiFlexItem>
        {this.renderLoginMessage(errorDescription, sentOtp)}
        <FormInput
          key={1}
          name="otp"
          placeholder="Passcode"
          onChange={onChange}
          onClick={onClick}
          focus
          defaultValue=""
        />
        <FuiFlexItem className="wrapContents" justifyContent="center">
          <FuiButton noFill onClick={resendOnClick} isDisabled={isResendOtpDisabled}>
            Resend
          </FuiButton>
          <FuiButton onClick={onClick}>Sign In</FuiButton>
        </FuiFlexItem>
      </Content>
    );
  }

  renderLoginMessage = (errorDescription, sentOtp) => {
    if (sentOtp) {
      return (
        <FuiFlexItem padding="none">
          <span className="message">
            We have sent a passcode to your phone.
          </span>
        </FuiFlexItem>
      );
    }
    if (errorDescription) {
      return (
        <FuiFlexItem padding="none">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <span className="error">{errorDescription}</span>
        </FuiFlexItem>
      );
    }

    return <> </>;
  };
}

OtpLogin.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

OtpLogin.defaultProps = {};
