import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiFlexItem,
  FuiPasswordBox,
  FuiTextBox,
} from "@forcuraco/forcura-ui-components";

class FormInput extends Component {
  constructor(props) {
    super(props);
    this.formInput = React.createRef();
  }

  componentDidMount() {
    this.setFocus();
  }

  setFocus = () => {
    const { focus } = this.props;
    if (focus) this.formInput.focus();
  };

  onChange = (e) => {
    const { onChange } = this.props;
    onChange(e);
  };

  onKeyPress = (e) => {
    const { onClick } = this.props;

    if (e.key === "Enter" && onClick) {
      onClick(e);
    }
  };

  render() {
    const {
      name,
      defaultValue,
      placeholder,
      onChange,
      password,
      onPasswordVisibilityChange,
      revealPassword,
    } = this.props;

    if (password) {
      return (
        <FuiFlexItem key={name} padding="none">
          <FuiPasswordBox
            name={name}
            inputRef={(input) => {
              this.formInput = input;
            }}
            autoComplete="off"
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={this.onKeyPress}
            defaultValue={defaultValue}
            onPasswordVisibilityChange={onPasswordVisibilityChange}
            revealPassword={revealPassword}
            className="inputbox"
          />
        </FuiFlexItem>
      );
    }

    return (
      <FuiFlexItem key={name} padding="none">
        <FuiTextBox
          name={name}
          inputRef={(input) => {
            this.formInput = input;
          }}
          className="inputbox"
          autoComplete="off"
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={this.onKeyPress}
          defaultValue={defaultValue}
        />
      </FuiFlexItem>
    );
  }
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  password: PropTypes.bool,
  focus: PropTypes.bool,
  onPasswordVisibilityChange: PropTypes.func,
  revealPassword: PropTypes.bool,
  onClick: PropTypes.func,
};

FormInput.defaultProps = {
  password: undefined,
  focus: false,
  onPasswordVisibilityChange: undefined,
  revealPassword: false,
  onClick: undefined,
};

export default FormInput;
