import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import Content from "../shared/Content";
import UserAgreementAcceptanceList from "../shared/UserAgreementAcceptanceList";

export default class AcceptUserAgreements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userAgreementsAccepted: [],
    };
  }

  setUserAgreementsAccepted = (userAgreementsAccepted) => {
    this.setState({ userAgreementsAccepted });
  };

  render() {
    const { userAgreementsAccepted } = this.state;
    const { userAgreements, onAgreementsAccepted } = this.props;

    return (
      <Content>
        <FuiFlexContainer
          direction="column"
          alignItems="center"
          alley="large"
          className="contentsContainer"
        >
          <FuiFlexItem>
            <FuiHeading>User Agreement</FuiHeading>
          </FuiFlexItem>
          <FuiFlexContainer direction="column" alignItems="center" alley="none">
            <FuiFlexItem padding="horizontal">
              Please review the updated user agreement
              {userAgreements.length > 1 ? "s" : ""} to continue to Circle.
            </FuiFlexItem>
            <UserAgreementAcceptanceList
              userAgreements={userAgreements}
              onUpdateAcceptance={this.setUserAgreementsAccepted}
              userAgreementsAccepted={userAgreementsAccepted}
            ></UserAgreementAcceptanceList>
          </FuiFlexContainer>
          <FuiButton
            color="primary"
            onClick={onAgreementsAccepted}
            disabled={userAgreementsAccepted.length < userAgreements.length}
          >
            Continue
          </FuiButton>
        </FuiFlexContainer>
      </Content>
    );
  }
}

AcceptUserAgreements.propTypes = {
  userAgreements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      accountId: PropTypes.number.isRequired,
      accountName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onAgreementsAccepted: PropTypes.func.isRequired,
};
