class NpiService {
    isValidNpi(npi, token) {
        return fetch(`/provider/${npi}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(token),
        });
    }

    isValidNameAndNpi(npi, firstName, lastName, token) {
        return fetch(
            `/provider/${npi}/firstname/${firstName}/lastname/${lastName}`,
            {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify(token),
            },
        )
    }
}

export const npiService = new NpiService();