import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiButton,
  FuiButtonNoOutline,
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiTooltip,
  FuiPasswordBox,
  FuiFlexContainer,
  FuiTextBox,
} from "@forcuraco/forcura-ui-components";
import { authService } from "../../services/AuthService";
import PasswordService from "../../services/PasswordService";
import Loading from "../shared/Loading";
import Content from "../shared/Content";
import Steps from "../shared/Steps";
import Error from "../shared/Error";
import {
  escapeFormattedMobileNumber,
  validatePhoneNumber,
} from "../../helpers/PhoneNumberValidator";
import MobileNumberConfirmationModal from "../shared/MobileNumberConfirmationModal";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");
    const returnUrl = query.get("ReturnUrl");
    const userId = query.get("userId");
    const clientId = query.get("clientId");

    const state = {
      loading: true,
      currentStep: 1,
      email: "",
      errorDescription: "",
      token,
      returnUrl,
      userId,
      clientId,
      passwordRulesMet: true,
      newPassword: "",
      newPasswordAgain: "",
      mustResetMfa: false,
      phoneNumber: undefined,
      phoneNumberError: undefined,
      showConfirmPhoneNumberModal: false,
    };

    this.state = state;
    this.validateToken(state);
    this.PasswordService = new PasswordService();
  }

  componentDidMount() {
    this.subscription = authService.subscribe(this.onStateChanged);
    const { loginState } = authService.getState();
    this.setState({ ...loginState, currentStep: 1 });
  }

  componentWillUnmount() {
    authService.unsubscribe(this.subscription);
  }

  onStateChanged = () => {
    const { loginState } = authService.getState();
    this.setState({ ...loginState, loading: false });
  };

  onNewPasswordChange = (value) => {
    this.setState({
      newPassword: value,
    });
  };

  onNewPasswordAgainChange = (value) => {
    this.setState({
      newPasswordAgain: value,
    });
  };

  onPasswordVisibilityChange = (isVisible) => {
    this.setState({
      revealNewPassword: isVisible,
    });
  };

  onPasswordAgainVisibilityChange = (isVisible) => {
    this.setState({
      revealNewPasswordAgain: isVisible,
    });
  };

  passwordOnBlur = () => {
    const { newPassword } = this.state;

    const passwordRulesMet = this.PasswordService.validatePassword(newPassword);

    this.setState({
      passwordRulesMet,
    });
  };

  onClick = async () => {
    const {
      currentStep,
      newPassword,
      newPasswordAgain,
      isErrored,
      isAuthenticated,
      token,
      mustResetMfa,
      phoneNumber,
      userId,
      clientId,
    } = this.state;

    if (isAuthenticated || isErrored) {
      this.setState({
        done: true,
      });
      return;
    }

    if (newPassword && newPassword !== newPasswordAgain) {
      this.setState({
        errorDescription: "Passwords must match",
      });
    }

    const passwordRulesMet = await this.PasswordService.validatePasswordHistory({token, clientId, userId, newPassword });

    const phoneNumberError = mustResetMfa && this.validatePhoneNumberInput();
    if (phoneNumberError) {
      this.setState({ phoneNumberError });
      return;
    }

    if (!passwordRulesMet && !isErrored) {
      this.setState({
        passwordRulesMet,
      });
      return;
    }

    if (!token) {
      this.setState({
        errorDescription: "Token is required",
      });
    }

    if (
      currentStep === 1 &&
      newPassword &&
      newPassword === newPasswordAgain &&
      token &&
      (!mustResetMfa || phoneNumber)
    ) {
      if (mustResetMfa) {
        authService.sendOtpToConfirmPhoneNumber(
          phoneNumber,
          userId,
          token,
          clientId,
        );
        this.setState({ showConfirmPhoneNumberModal: true });
        return;
      }

      this.submitPasswordChange();
    }
  };

  validateToken = (request) => {
    authService.validateToken(request);
  };

  submitPasswordChange = () => {
    const { token, newPassword, phoneNumber } = this.state;
    const request = {
      ...this.state,
      token,
      newPassword,
      currentStep: 2,
      loading: true,
      phoneNumber,
      showConfirmPhoneNumberModal: false,
    };

    this.closeConfirmPhoneNumberModal();
    authService.changePassword(request);
  };

  validatePhoneNumberInput = () => {
    const { phoneNumber } = this.state;

    const formattedPhoneNumber = escapeFormattedMobileNumber(phoneNumber);
    const phoneNumberError = validatePhoneNumber(
      "Mobile Number",
      formattedPhoneNumber,
    );

    return phoneNumberError ? [phoneNumberError] : null;
  };

  phoneNumberOnBlur = () => {
    this.setState({
      phoneNumberError: this.validatePhoneNumberInput(),
    });
  };

  onChangePhoneNumber = (e) => {
    this.setState({
      phoneNumber: escapeFormattedMobileNumber(e.target.value),
    });
  };

  closeConfirmPhoneNumberModal = () => {
    this.setState({ showConfirmPhoneNumberModal: false });
  };

  renderDone = () => {
    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="medium">
          <FuiFlexItem>
            <FuiHeading>Success!</FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem padding="none">
            <FuiIcon color="#4bd17f" size="lg" type="checkMark" />
          </FuiFlexItem>
          <FuiFlexItem padding="none">
            <span className="info">
              Your password was updated successfully. Please login with your new
              credentials.
            </span>
          </FuiFlexItem>
          <FuiFlexItem>
            <Steps totalSteps={2} currentStep={2} />
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiButton onClick={this.onClick}>Done</FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  };

  render() {
    const {
      state: {
        loading,
        currentStep,
        passwordRulesMet,
        error,
        errorDescription,
        requestId,
        done,
        isErrored,
        isAuthenticated,
        returnUrl,
        revealNewPassword,
        revealNewPasswordAgain,
        newPassword,
        newPasswordAgain,
      },
      props: { location },
      onNewPasswordChange,
      onNewPasswordAgainChange,
      onPasswordVisibilityChange,
      onPasswordAgainVisibilityChange,
      passwordOnBlur,
    } = this;

    if (loading) {
      return <Loading />;
    }

    if (done) {
      window.location.replace(`${returnUrl}`);
    }

    if (isAuthenticated) {
      return this.renderDone();
    }

    if (isErrored) {
      return (
        <Error
          requestId={requestId}
          error={error}
          errorDescription={errorDescription}
          location={location}
        />
      );
    }

    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="medium">
          <FuiFlexItem>
            <FuiHeading>Set New Password</FuiHeading>
          </FuiFlexItem>
          {this.renderPasswordChangeMessage(passwordRulesMet, errorDescription)}
          <FuiFlexItem>
            <FuiPasswordBox
              key={2}
              name="newPassword"
              className="inputbox"
              placeholder="Enter Password"
              onChange={onNewPasswordChange}
              onBlur={passwordOnBlur}
              onPasswordVisibilityChange={onPasswordVisibilityChange}
              revealPassword={revealNewPassword}
              value={newPassword}
              tabIndex={1}
            />
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiPasswordBox
              key={3}
              name="newPasswordAgain"
              className="inputbox"
              placeholder="Confirm Password"
              onChange={onNewPasswordAgainChange}
              onPasswordVisibilityChange={onPasswordAgainVisibilityChange}
              revealPassword={revealNewPasswordAgain}
              value={newPasswordAgain}
              tabIndex={2}
            />
          </FuiFlexItem>
          {this.renderPasswordRequirements(
            currentStep,
            this.onChange,
            this.onClick,
          )}
          {this.renderPhoneNumber()}
          {this.renderStepIndicator(2, currentStep)}
          {this.renderSubmitButton(currentStep, this.onClick)}
        </FuiFlexContainer>
        {this.renderConfirmPhoneNumberModal()}
      </Content>
    );
  }

  renderPasswordChangeMessage = (passwordRulesMet, errorDescription) => {
    if (!passwordRulesMet) {
      return (
        <FuiFlexItem className="passwordRequirementsContainer">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <div className="error">
            <FuiFlexItem grow={false}>
              <FuiFlexItem direction="column">
                <FuiFlexItem>
                  <span>
                    Your password must be at least 8 characters and cannot be
                    the same as your 10 previous passwords.
                  </span>
                </FuiFlexItem>
                <FuiFlexItem>
                  <span>
                    It also must contain at least three of the following:
                  </span>
                </FuiFlexItem>
                <FuiFlexItem>
                  <ul>
                    <li key="upper">One uppercase character</li>
                    <li key="lower">One lowercase character</li>
                    <li key="number">One number</li>
                    <li key="special">One special character like $, % or !</li>
                  </ul>
                </FuiFlexItem>
              </FuiFlexItem>
            </FuiFlexItem>
          </div>
        </FuiFlexItem>
      );
    }

    if (errorDescription) {
      return (
        <FuiFlexItem className="passwordRequirementsContainer">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <div className="error">{errorDescription} </div>
        </FuiFlexItem>
      );
    }

    return <> </>;
  };

  renderPasswordRequirements = () => {
    const content = (
      <FuiFlexItem grow={false} className="passwordRequirementsContainer">
        <FuiFlexItem className="directory-info" direction="column">
          <FuiFlexItem>
            <span>
              Your password must be at least 8 characters and cannot be the same
              as your 10 previous passwords.
            </span>
          </FuiFlexItem>
          <FuiFlexItem>
            <span>It also must contain at least three of the following:</span>
          </FuiFlexItem>
          <FuiFlexItem>
            <ul>
              <li>One uppercase character</li>
              <li>One lowercase character</li>
              <li>One number</li>
              <li>One special character like $, % or !</li>
            </ul>
          </FuiFlexItem>
        </FuiFlexItem>
      </FuiFlexItem>
    );

    return (
      <FuiFlexItem className="requirementsItem">
        <FuiIcon color="#32bfc6" size="sm" type="circleInfo" />
        <FuiTooltip enableOverflow="viewport" content={content}>
          <FuiButtonNoOutline className="passwordRequirements">
            Password Requirements
          </FuiButtonNoOutline>
        </FuiTooltip>
      </FuiFlexItem>
    );
  };

  renderStepIndicator = (totalSteps, currentStep) => {
    return (
      <FuiFlexItem>
        <Steps
          totalSteps={totalSteps}
          currentStep={currentStep === 2 ? 2 : 1}
        />
      </FuiFlexItem>
    );
  };

  renderSubmitButton = (step, onClick) => {
    const text = step !== 2 ? "Next" : "Change Password";
    return (
      <FuiFlexItem>
        <FuiButton onClick={onClick} tabIndex={4}>
          {text}
        </FuiButton>
      </FuiFlexItem>
    );
  };

  renderPhoneNumber = () => {
    const { phoneNumber, phoneNumberError } = this.state;
    if (!this.state.mustResetMfa) {
      return <></>;
    }

    return (
      <FuiFlexItem>
        <FuiTextBox
          className="inputbox"
          autoComplete="off"
          name="mobilePhoneNumber"
          placeholder="Mobile Number"
          value={phoneNumber || ""}
          onChange={this.onChangePhoneNumber}
          errors={phoneNumberError}
          onBlur={this.phoneNumberOnBlur}
          tabIndex={3}
        />
      </FuiFlexItem>
    );
  };

  renderConfirmPhoneNumberModal = () => {
    const {
      showConfirmPhoneNumberModal,
      phoneNumber,
      token,
      userId,
      clientId,
    } = this.state;

    return (
      <MobileNumberConfirmationModal
        clientId={clientId}
        token={token}
        userId={userId}
        mobilePhoneNumber={phoneNumber || ""}
        displayModal={showConfirmPhoneNumberModal}
        closeModal={this.closeConfirmPhoneNumberModal}
        confirmMobileNumber={authService.confirmMobileNumber}
        onSuccessfulMobileNumberConfirmation={this.submitPasswordChange}
      />
    );
  };
}

ResetPassword.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};
