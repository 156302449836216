/* eslint-disable import/no-unused-modules */
import { Paths } from "../helpers/Constants";

export default class AuthOtpService {
  constructor() {
    this.callbacks = [];
    this.nextSubscriptionId = 6000;
    this.loginState = {};
    this.errorState = {};
  }

  handlePreAuthenticateResponse = (loginResponse) => {
    if (loginResponse.isFederated) {
      window.location.replace(
        `${Paths.ApiExternalLoginUrl}?id=${loginResponse.federationProviderId}&returnUrl=${loginResponse.returnUrl}&email=${loginResponse.email}&browser=${loginResponse.browser}&deviceId=${loginResponse.deviceId}&ipAddress=${loginResponse.ipAddress}`,
      );
    } else {
      this.updateLoginState({ ...loginResponse, loading: false });
    }
  };

  fetchWithHeaders = (token, path, options) => {
    const optionsHeaders = options ? options.headers : {};
    const headers = {
      ...optionsHeaders,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    return fetch(path, { ...options, headers });
  };

  authenticateOtp = (loginRequest) => {
    this.updateLoginState(loginRequest);
    if (loginRequest.otpNumber && loginRequest.token) {
      this.fetchWithHeaders(loginRequest.token, "/Otp/LoginOtp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(loginRequest),
      })
        .then((resp) => resp.json())
        .then((payload) =>
          this.handleAuthenticateResponse({
            ...loginRequest,
            ...payload,
          }),
        );
    }
  };

  sendOtp = (token, optRequest) => {
    if (token) {
      this.fetchWithHeaders(token, "/Otp/SendOtp", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(optRequest),
      })
        .then((resp) => resp.json())
        .then((responseData) => {
          this.updateLoginState({
            ...responseData,
            loading: false,
            errorDescription:
              responseData.errorDescription || "The link has expired.",
          });
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.warn(error);

          this.updateLoginState({
            errorDescription: "The link has expired.",
            loading: false,
          });
        });
    }
  };

  handleAuthenticateResponse = (loginResponse) => {
    if (loginResponse.isAuthenticated) {
      if (loginResponse.mustUpdatePassword) {
        this.updateLoginState({
          ...loginResponse,
          isAuthenticated: false,
          loading: false,
        });
      } else {
        window.location.replace(`${loginResponse.returnUrl}?register=true`);
      }
    } else {
      this.updateLoginState({ ...loginResponse, loading: false });
    }
  };

  updateLoginState = (newState) => {
    this.loginState = newState;
    this.notifySubscribers();
  };

  getState = () => {
    return {
      loginState: { ...this.loginState },
    };
  };

  subscribe = (callback) => {
    this.callbacks.push({
      callback,
      // eslint-disable-next-line no-plusplus
      subscription: this.nextSubscriptionId++,
    });
    return this.nextSubscriptionId - 1;
  };

  unsubscribe = (subscriptionId) => {
    const subscriptionIndex = this.callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false },
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
      );
    }

    this.callbacks.splice(subscriptionIndex[0].index, 1);
  };

  notifySubscribers = () => {
    for (let i = 0; i < this.callbacks.length; i += 1) {
      const { callback } = this.callbacks[i];
      callback();
    }
  };
}

export const authOtpService = new AuthOtpService();
