import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiPasswordBox,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import { authService } from "../../services/AuthService";
import Loading from "../shared/Loading";
import Content from "../shared/Content";
import Steps from "../shared/Steps";
import PasswordRequirements from "../shared/PasswordRequirements";

const PageSteps = {
  EnterPasswordInformation: 1,
  End: 2,
};

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    const returnUrl = query.get("ReturnUrl");
    const token = query.get("token");
    const userId = query.get("userId");
    const clientId = query.get("clientId");

    this.state = {
      loading: false,
      currentStep: PageSteps.EnterPasswordInformation,
      email: "",
      errorDescription: "",
      returnUrl,
      newPassword: "",
      newPasswordAgain: "",
      password: "",
      passwordError: null,
      newPasswordError: null,
      newPasswordAgainError: null,
      token,
      userId,
      clientId
    };

    this.AuthService = authService;
  }

  submitPasswordChange = (loginRequest) => {
    this.AuthService.changePassword(loginRequest);
  };

  componentDidMount() {
    this.subscription = this.AuthService.subscribe(this.onStateChanged);
    const { loginState } = this.AuthService.getState();
    this.setState({ ...loginState, currentStep: 1 });
  }

  componentWillUnmount() {
    this.AuthService.unsubscribe(this.subscription);
  }

  onStateChanged = () => {
    const { loginState } = this.AuthService.getState();
    this.setState({ ...loginState });
  };

  onPasswordChange = (value) => {
    this.setState({
      password: value,
    });
  };

  onPasswordVisibilityChange = (isVisible) => {
    this.setState({
      revealPassword: isVisible,
    });
  };

  onNewPasswordChange = (value) => {
    this.setState({
      newPassword: value,
    });
  };

  onNewPasswordVisibilityChange = (isVisible) => {
    this.setState({
      revealNewPassword: isVisible,
    });
  };

  onNewPasswordAgainChange = (value) => {
    this.setState({
      newPasswordAgain: value,
    });
  };

  onNewPasswordAgainVisibilityChange = (isVisible) => {
    this.setState({
      revealNewPasswordAgain: isVisible,
    });
  };

  onClick = () => {
    const {
      currentStep,
      newPassword,
      newPasswordAgain,
      password,
      isAuthenticated,
    } = this.state;
    let errorDescription = null;
    let passwordError = null;
    let newPasswordError = null;
    let newPasswordAgainError = null;

    if (!newPassword) {
      newPasswordError = ["New password is required"];
    }

    if (!newPasswordAgain) {
      newPasswordAgainError = ["New password again is required"];
    }

    if (!password) {
      passwordError = ["Current password is required"];
    }

    if (newPassword && newPassword !== newPasswordAgain) {
      errorDescription = "Passwords must match";

      newPasswordError = [];
      newPasswordAgainError = [];
    }

    if (passwordError || newPasswordError || newPasswordAgainError) {
      this.setState({
        passwordError,
        errorDescription,
        newPasswordError,
        newPasswordAgainError,
        error: null,
      });

      return;
    }

    switch (currentStep) {
      case PageSteps.EnterPasswordInformation:
        this.submitPasswordChange({
          ...this.state,
          password,
          newPassword,
          currentStep: 3,
          loading: true,
        });
        this.setState({
          password: "",
          newPassword: "",
          newPasswordAgain: "",
        });
        break;
      case PageSteps.End:
        break;
      default:
        break;
    }

    if (isAuthenticated) {
      this.AuthService.resetState();
      this.setState({
        done: true,
      });
    }
  };

  renderDone() {
    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="medium">
          <FuiFlexItem>
            <FuiHeading>Success!</FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem padding="none">
            <FuiIcon color="#4bd17f" size="lg" type="circleCheck" />
          </FuiFlexItem>
          <FuiFlexItem padding="none">
            <span className="info">
              Thank you for taking the time to update your password. Please
              login with your new credentials.
            </span>
          </FuiFlexItem>
          <FuiFlexItem>
            <Steps totalSteps={2} currentStep={PageSteps.End} />
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiButton onClick={this.onClick}>Done</FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }

  render() {
    const {
      loading,
      errorDescription,
      newPasswordRequested,
      passwordError,
      newPasswordError,
      newPasswordAgainError,
      done,
      isAuthenticated,
      error,
      returnUrl,
      revealPassword,
      revealNewPassword,
      revealNewPasswordAgain,
      password,
      newPassword,
      newPasswordAgain,
    } = this.state;

    const { renderRedirect } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (done) {
      return renderRedirect("login", returnUrl);
    }

    if (isAuthenticated) {
      return this.renderDone();
    }

    const onSubmit = (e) => {
      this.onClick();
      e.preventDefault();
      return false;
    };

    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="large">
          <FuiFlexItem>
            <FuiHeading>Change your password</FuiHeading>
          </FuiFlexItem>
          <form className="fuiFlexItem fuiFlexItem--column" onSubmit={onSubmit}>
            <FuiFlexContainer
              direction="column"
              alignItems="center"
              alley="medium"
            >
              {this.renderPasswordChangeMessage(
                errorDescription,
                newPasswordRequested,
                error,
              )}
              <FuiFlexItem>
                <FuiPasswordBox
                  key="currentPassword"
                  name="password"
                  className={"inputbox" + passwordError ? " hasError" : ""}
                  defaultValue=""
                  placeholder="Current Password"
                  onChange={this.onPasswordChange}
                  errors={passwordError}
                  revealPassword={revealPassword}
                  onPasswordVisibilityChange={this.onPasswordVisibilityChange}
                  value={password}
                />
              </FuiFlexItem>
              <FuiFlexItem>
                <FuiPasswordBox
                  className={"inputbox" + newPasswordError ? " hasError" : ""}
                  key="newPassword"
                  name="newPassword"
                  defaultValue=""
                  placeholder="New Password"
                  onChange={this.onNewPasswordChange}
                  errors={newPasswordError}
                  revealPassword={revealNewPassword}
                  onPasswordVisibilityChange={
                    this.onNewPasswordVisibilityChange
                  }
                  value={newPassword}
                />
              </FuiFlexItem>
              <FuiFlexItem>
                <FuiPasswordBox
                  className={
                    "inputbox" + newPasswordAgainError ? " hasError" : ""
                  }
                  key="newPasswordAgain"
                  name="newPasswordAgain"
                  defaultValue=""
                  placeholder="New Password Again"
                  onChange={this.onNewPasswordAgainChange}
                  errors={newPasswordAgainError}
                  revealPassword={revealNewPasswordAgain}
                  onPasswordVisibilityChange={
                    this.onNewPasswordAgainVisibilityChange
                  }
                  value={newPasswordAgain}
                />
              </FuiFlexItem>
              <FuiFlexItem>
                <Steps
                  totalSteps={2}
                  currentStep={PageSteps.EnterPasswordInformation}
                />
              </FuiFlexItem>
              <FuiFlexItem>
                <FuiButton type="submit">Change Password</FuiButton>
              </FuiFlexItem>
            </FuiFlexContainer>
          </form>
        </FuiFlexContainer>
      </Content>
    );
  }

  renderPasswordChangeMessage = (
    errorDescription,
    newPasswordRequested,
    error,
  ) => {
    if (error === "password_rules_not_met") {
      return (
        <FuiFlexItem padding="none">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <span className="error">
            <PasswordRequirements ispasswordReset />
          </span>
        </FuiFlexItem>
      );
    }

    if (errorDescription) {
      return (
        <FuiFlexItem padding="none">
          <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
          &nbsp;
          <span className="error">{errorDescription}</span>
        </FuiFlexItem>
      );
    }

    if (!newPasswordRequested) {
      return (
        <FuiFlexItem padding="none">
          <span id="passwordResetInfo" className="info">
            Your password has expired and needs to be updated.
          </span>
        </FuiFlexItem>
      );
    }

    return <></>;
  };
}

ChangePassword.propTypes = {
  renderRedirect: PropTypes.func.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};
