import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContextProvider from "../../context/AuthContextProvider";
import { LoginLogosTypes } from "../../helpers/Constants";

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, location, loginLogoType, ...rest }) => {
  return (
    <AuthContextProvider location={location} loginLogoType={loginLogoType}>
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(routeProps) => {
          return renderMergedProps(component, routeProps, rest);
        }}
      />
    </AuthContextProvider>
  );
};

PropsRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }),
  loginLogoType: PropTypes.oneOf([
    LoginLogosTypes.Alloy,
    LoginLogosTypes.Circle,
  ]),
};

export default PropsRoute;
