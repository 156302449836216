import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiIcon,
  FuiTextBox,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import Loading from "../shared/Loading";
import Content from "../shared/Content";
import Steps from "../shared/Steps";
import PasswordService from "../../services/PasswordService";

const PageSteps = {
  EnterEmailAddress: 1,
  End: 2,
};

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentStep: PageSteps.EnterEmailAddress,
      emailError: [],
      email: props.email ? props.email : undefined,
      applicationReturnUrl: props.applicationReturnUrl,
    };

    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      const email = queryParams.get("email_hint");
      const returnUrl = queryParams.get("return_url");

      if (email && !this.state.email) {
        this.state.readonly = true;
      }

      this.state.email = this.state.email || email;
      this.state.applicationReturnUrl =
        this.state.applicationReturnUrl || returnUrl;
    }

    this.PasswordService = new PasswordService();
  }

  submitForgotPassword = (email, applicationReturnUrl) => {
    this.PasswordService.forgotPassword(email, applicationReturnUrl);
  };

  componentDidMount() {
    this.subscription = this.PasswordService.subscribe(this.onStateChanged);

    if (this.emailInput) {
      this.emailInput.focus();
    }
  }

  componentWillUnmount() {
    this.PasswordService.unsubscribe(this.subscription);
  }

  shouldComponentUpdate() {
    return true;
  }

  onStateChanged = () => {
    const { passwordState } = this.PasswordService.getState();
    this.setState({ ...passwordState });
  };

  onChange = (e) => {
    switch (e.target.name) {
      case "email":
        this.setState({
          email: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.onChange(e);
      this.onClick();
    }
  };

  onCancel = () => {
    this.setState({
      done: true,
      currentStep: PageSteps.EnterEmailAddress,
    });
  };

  onClick = () => {
    const { email, currentStep, applicationReturnUrl } = this.state;

    const emailError = this.emailValidation(email);

    if (emailError) {
      this.setState({
        emailError: [emailError],
      });

      return;
    }

    switch (currentStep) {
      case PageSteps.EnterEmailAddress:
        this.setState({
          currentStep: PageSteps.End,
          loading: true,
        });
        this.submitForgotPassword(email, applicationReturnUrl);
        break;
      case PageSteps.End:
        this.setState({ done: true, currentStep: PageSteps.End });
        break;
      default:
        break;
    }
  };

  emailValidation(value) {
    if (!value || value.trim() === "") {
      return "Please enter an email address";
    }
    /*
    user names are not always email addresses, this allows to reset a user with the username.
    if (!/^\S+@\S+$/.test(value)) {
      return "Please enter a valid email address";
    }
    */

    return null;
  }

  renderDone() {
    const { error, errorDescription } = this.state;

    if (error) {
      return (
        <Content>
          <FuiFlexContainer
            direction="column"
            alignItems="center"
            alley="medium"
          >
            <FuiFlexItem>
              <FuiHeading>Error!</FuiHeading>
            </FuiFlexItem>
            <FuiFlexItem padding="none">
              <FuiIcon color="#f2545b" size="sm" type="triangleExclamation" />
              &nbsp;
              <span className="error">{errorDescription}</span>
            </FuiFlexItem>
            <FuiFlexItem>
              <Steps totalSteps={2} currentStep={PageSteps.End} />
            </FuiFlexItem>
            <FuiFlexItem>
              <FuiButton onClick={this.onClick}>Done</FuiButton>
            </FuiFlexItem>
          </FuiFlexContainer>
        </Content>
      );
    }

    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="medium">
          <FuiFlexItem>
            <FuiHeading>Success!</FuiHeading>
          </FuiFlexItem>
          <FuiFlexItem padding="none">
            <FuiIcon color="#4bd17f" size="lg" type="circleCheck" />
          </FuiFlexItem>
          <FuiFlexItem padding="none">
            <span id="passwordResetInfo" className="info">
              Your password has been reset! Please check your email for further
              instructions.
            </span>
          </FuiFlexItem>
          <FuiFlexItem>
            <Steps totalSteps={2} currentStep={PageSteps.End} />
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiButton id="resetPasswordDone" onClick={this.onClick}>Done</FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }

  reload() {
    window.location.reload();
  }

  render() {
    const { loading, emailError, email, done, passwordReset } = this.state;
    const { reload } = this;
    const { isLockedOut } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (done) {
      reload();
    }

    if (passwordReset) {
      return this.renderDone();
    }

    return (
      <Content>
        <FuiFlexContainer direction="column" alignItems="center" alley="large">
          <FuiFlexItem>
            <FuiHeading>Enter Your Email Address</FuiHeading>
          </FuiFlexItem>
          {isLockedOut && (
            <FuiFlexItem>
              <FuiIcon color="#f2545b" size="md" type="triangleExclamation" />
              <span className="error">
                You will need to reset your password to login. Please click
                &ldquo;Reset Password.&ldquo;
              </span>
            </FuiFlexItem>
          )}
          <FuiFlexItem>
            <FuiTextBox
              inputRef={(input) => {
                this.emailInput = input;
              }}
              autoComplete="off"
              name="email"
              placeholder="Email"
              onChange={this.onChange}
              errors={emailError}
              onKeyDown={this.keyPress}
              defaultValue={email}
              className="inputbox"
              readOnly={this.state.readonly}
            />
          </FuiFlexItem>
          <FuiFlexItem>
            <Steps totalSteps={2} currentStep={PageSteps.EnterEmailAddress} />
          </FuiFlexItem>
          <FuiFlexItem className="wrapOnExtraSmall" justifyContent="center">
            <FuiButton onClick={this.onCancel} noFill>
              Back
            </FuiButton>
            <FuiButton id="resetPassword" onClick={this.onClick}>Reset Password</FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
      </Content>
    );
  }
}

ForgotPassword.propTypes = {
  email: PropTypes.string,
  applicationReturnUrl: PropTypes.string.isRequired,
  isLockedOut: PropTypes.bool,
};

ForgotPassword.defaultProps = { email: undefined, isLockedOut: false };
