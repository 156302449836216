import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FuiButton,
  FuiFlexItem,
  FuiHeading,
  FuiPasswordBox,
  FuiTextBox,
  FuiFlexContainer,
} from "@forcuraco/forcura-ui-components";
import PasswordService from "../../../services/PasswordService";
import {
  validatePhoneNumber,
  escapeFormattedMobileNumber,
  formatMobileNumber,
} from "../../../helpers/PhoneNumberValidator";
import Content from "../../shared/Content";
import Steps from "../../shared/Steps";
import Loading from "../../shared/Loading";
import MFAMessagingChangesInfo from "../../shared/MFAMessagingChangesInfo";
import PasswordRequirementsToolTip from "../../shared/PasswordRequirementsToolTip";
import PasswordRequirements from "../../shared/PasswordRequirements";
import MobileNumberConfirmationModal from "../../shared/MobileNumberConfirmationModal";

export default class PasswordAndPhone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      mobilePhoneNumber: "",
      mobilePhoneNumberError: [],
      passwordRulesMet: true,
      loading: false,
      displayMobileNumberConfirmationModal: false,
    };

    this.PasswordService = new PasswordService();
  }

  componentDidMount() {
    const { registerService } = this.props
    this.subscription = registerService.subscribe(this.onStateChanged);
    const { registerState } = registerService.getState();
    this.setState({ ...registerState });
  }

  componentWillUnmount() {
    const { registerService } = this.props
    registerService.unsubscribe(this.subscription);
  }

  onStateChanged = () => {
    const { registerService } = this.props
    const { registerState } = registerService.getState();

    this.setState({ ...registerState });
  };

  onSubmit = () => {
    const { mobilePhoneNumber, password, firstName, lastName, token } = this.state;
    const { registerService } = this.props

    const phoneNumber = escapeFormattedMobileNumber(mobilePhoneNumber);
    const mobilePhoneNumberError = validatePhoneNumber(
      "Mobile Number",
      phoneNumber,
    );
    const passwordRulesMet = this.PasswordService.validatePassword(password);

    this.setState({
      mobilePhoneNumberError:
        mobilePhoneNumberError === null ? null : [mobilePhoneNumberError],
      passwordRulesMet,
    });

    if (passwordRulesMet && mobilePhoneNumberError === null) {
      this.setState({
        displayMobileNumberConfirmationModal: true,
      });

      registerService.sendOtpToConfirmMobileNumber(
        phoneNumber,
        password,
        firstName,
        lastName,
        token,
        "",
      );
    }
  };

  closeMobileNumberConfirmationModal = () => {
    this.setState({
      displayMobileNumberConfirmationModal: false,
    });
  };

  onCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  mobilePhoneNumberOnBlur = () => {
    const { mobilePhoneNumber } = this.state;

    const phoneNumber = escapeFormattedMobileNumber(mobilePhoneNumber);
    const mobilePhoneNumberError = validatePhoneNumber(
      "Mobile Number",
      phoneNumber,
    );
    this.setState({
      mobilePhoneNumberError:
        mobilePhoneNumberError === null ? null : [mobilePhoneNumberError],
    });
  };

  passwordOnBlur = () => {
    const { password } = this.state;

    const passwordRulesMet = this.PasswordService.validatePassword(password);
    this.setState({
      passwordRulesMet,
    });
  };

  onPasswordChange = (value) => {
    this.setState({
      password: value,
    });
  };

  onPasswordVisibilityChange = (isVisible) => {
    this.setState({
      revealPassword: isVisible,
    });
  };

  onChange = (e) => {
    switch (e.target.name) {
      case "mobilePhoneNumber":
        this.setState({
          mobilePhoneNumber: escapeFormattedMobileNumber(e.target.value),
        });
        break;

      default:
        break;
    }
  };

  renderPassword = (
    passwordRulesMet,
    onChange,
    onBlur,
    revealPassword,
    onPasswordVisibilityChange,
    value,
  ) => {
    let errorText = null;
    if (!passwordRulesMet) {
      const errorUi = <PasswordRequirements />;

      errorText = [errorUi];
    }

    return (
      <FuiFlexItem grow className="registrationInput">
        <FuiPasswordBox
          key={2}
          name="password"
          placeholder="Password"
          className="inputbox"
          onChange={onChange}
          errors={errorText}
          onBlur={onBlur}
          revealPassword={revealPassword}
          onPasswordVisibilityChange={onPasswordVisibilityChange}
          value={value}
        />
      </FuiFlexItem>
    );
  };

  render() {
    const {
      mobilePhoneNumberError,
      passwordRulesMet,
      loading,
      revealPassword,
      password,
      displayMobileNumberConfirmationModal,
      mobilePhoneNumber,
      token
    } = this.state;

    const { onSubmit, registerService } = this.props;

    if (loading) {
      return <Loading />;
    }

    const phoneNumber = formatMobileNumber(mobilePhoneNumber);

    return (
      <Content>
        <FuiFlexContainer className="contentsContainer"
          alignItems="center"
          direction="column"
          alley="medium">
          <FuiFlexItem>
            <FuiHeading>Set Password</FuiHeading>
          </FuiFlexItem>
          {this.renderPassword(
            passwordRulesMet,
            this.onPasswordChange,
            this.passwordOnBlur,
            revealPassword,
            this.onPasswordVisibilityChange,
            password,
          )}
          <PasswordRequirementsToolTip />
          <FuiFlexItem grow className="registrationInput">
            <FuiTextBox
              className="inputbox"
              autoComplete="off"
              name="mobilePhoneNumber"
              placeholder="Mobile Number"
              value={phoneNumber || ""}
              onChange={this.onChange}
              errors={mobilePhoneNumberError}
              onBlur={this.mobilePhoneNumberOnBlur}
            />
          </FuiFlexItem>
          <MFAMessagingChangesInfo />
          <FuiFlexItem>
            <Steps totalSteps={2} currentStep={2} />
          </FuiFlexItem>
          <FuiFlexItem>
            <FuiButton color="primary" onClick={this.onSubmit}>
              Sign In
            </FuiButton>
          </FuiFlexItem>
        </FuiFlexContainer>
        {displayMobileNumberConfirmationModal && (<MobileNumberConfirmationModal
          displayModal={displayMobileNumberConfirmationModal}
          onSuccessfulMobileNumberConfirmation={onSubmit}
          closeModal={this.closeMobileNumberConfirmationModal}
          confirmMobileNumber={registerService.confirmMobileNumber}
          mobilePhoneNumber={mobilePhoneNumber}
          token={token}
        />)}
      </Content>
    );
  }
}

PasswordAndPhone.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
